import LoadingPage from 'pages/loading_page';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import Routes from '../../constants/routes';
import { upcomingEventsSelector } from '../../redux/events/ducks';
import { signOutUser, userClaimsSelector, userProfileSelector, userSelector } from '../../redux/users/ducks';
import { checkProfileRequirement } from '../../utils/auth-utils';

const withAdminAuthenticatedRoute = (ChildComponent) => (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const claims = useSelector(userClaimsSelector);
  const profile = useSelector(userProfileSelector);
  const user = useSelector(userSelector);
  const { loading: upcomingEventsLoading } = useSelector(upcomingEventsSelector);

  const isLoading = profile === undefined || upcomingEventsLoading || claims.loading;

  useEffect(() => {
    if (!user) {
      history.replace(Routes.LANDING);
    } else if (isLoading) {
      return;
    }
    if (profile === null) {
      history.replace(Routes.LANDING);
    } else if (!isLoading) {
      if (!claims.admin) {
        dispatch(signOutUser());
        history.replace(Routes.LANDING);
      } else if (checkProfileRequirement(profile) && location.pathname !== Routes.PROFILE) {
        history.replace(Routes.PROFILE);
      }
    }
  }, [claims, dispatch, history, isLoading, location, profile, user]);

  if (isLoading) {
    return <LoadingPage />;
  }

  return <ChildComponent {...props} />;
};

export default withAdminAuthenticatedRoute;
