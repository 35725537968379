import { Button, Chip, Grid, makeStyles, Typography } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import withAdminAuthenticatedRoute from 'components/hoc/withAdminAuthenticatedRoute';
import Layout from 'components/navigation/Layout';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setNotification } from 'redux/notifications/ducks';
import { fetchUserBookings, fetchUserById } from 'services/users-service';
import BookingsTable from '../components/common/BookingTable';
import SEO from '../components/common/SEO';
import { CategoriesJson } from '../constants/categories';
import EventColumns from '../constants/grids/events_columns';
import { promoteUserToOrganiser } from '../services/orgaizers-service';
import { formatDate } from '../utils/date-time-utils';
import PageNotFound from './404';
import LoadingPage from './loading_page';

const useStyles = makeStyles((theme) => ({
  chips: {
    'display': 'flex',
    'flexWrap': 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

const UserPage = ({
  match: {
    params: { id: userId },
  },
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const inFlight = useRef(false);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [promoted, setPromoted] = useState(false);
  const [user, setUser] = useState(null);

  const onPromote = async () => {
    if (inFlight.current) return;
    try {
      inFlight.current = true;
      await promoteUserToOrganiser(userId);
      setPromoted(true);
      dispatch(setNotification('Successfully promoted to Organizer', 'success'));
    } catch (e) {
      console.error(e);
      dispatch(setNotification(e.message));
    } finally {
      inFlight.current = false;
    }
  };

  useEffect(() => {
    if (userId) {
      (async () => {
        try {
          setLoading(true);
          const user = await fetchUserById(userId);
          setUser(user);
        } catch (e) {
          console.error(e);
          setError(true);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [userId]);

  if (loading) {
    return <LoadingPage />;
  } else if (error) {
    return <PageNotFound />;
  }

  return (
    <Layout>
      <SEO title={`${user.firstName} ${user.lastName}`} />
      <Typography variant={'h2'} gutterBottom>
        User Details
      </Typography>

      <Grid container spacing={4}>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
            <Typography variant={'h3'}>Personal Information</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography gutterBottom style={{ fontWeight: 'bold' }}>
              First Name *
            </Typography>
            <Typography>{user.firstName}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography gutterBottom style={{ fontWeight: 'bold' }}>
              Last Name *
            </Typography>
            <Typography>{user.lastName}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography gutterBottom style={{ fontWeight: 'bold' }}>
              User ID
            </Typography>
            <Typography>{user.id}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography gutterBottom style={{ fontWeight: 'bold' }}>
              Email ID
            </Typography>
            <Typography>{user.email}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography gutterBottom style={{ fontWeight: 'bold' }}>
              Phone Number
            </Typography>
            <Typography>{user.phoneNumber}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography gutterBottom style={{ fontWeight: 'bold' }}>
              Sign Up
            </Typography>
            <Typography>{formatDate(user.createdAt)}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography gutterBottom style={{ fontWeight: 'bold' }}>
              Location
            </Typography>
            <Typography>{user.location}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography gutterBottom style={{ fontWeight: 'bold' }}>
              Razorpay ID
            </Typography>
            <Typography>{user.customerId}</Typography>
          </Grid>
        </Grid>

        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
            <Typography variant={'h3'}>Categories</Typography>
          </Grid>
          <Grid item xs={6} className={classes.chips}>
            {user.categories.map((category) => (
              <Chip key={category} label={CategoriesJson[category]} />
            ))}
          </Grid>
        </Grid>

        {!promoted && !user.organizer && (
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12}>
              <Typography variant={'h3'}>Organiser</Typography>
            </Grid>
            <Grid item xs={12}>
              <Button onClick={onPromote} variant='contained' color='primary'>
                Promote to Organiser
              </Button>
            </Grid>
          </Grid>
        )}

        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
            <Typography variant={'h3'}>Bookmarked Events</Typography>
          </Grid>
          <Grid item xs={12}>
            <DataGrid
              autoHeight
              columns={EventColumns}
              // checkboxSelection
              // disableColumnMenu
              disableSelectionOnClick
              // hideFooterPagination
              // hideFooterSelectedRowCount
              pageSize={100}
              rows={user.bookmarkedEvents}
              rowsPerPageOptions={[100]}
              scrollbarSize={70}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <BookingsTable bookings={user.bookings} fetchData={() => fetchUserBookings(userId, true)} />
        </Grid>
      </Grid>
    </Layout>
  );
};

UserPage.propTypes = { match: PropTypes.shape({ params: PropTypes.shape({ id: PropTypes.string }) }) };

export default withAdminAuthenticatedRoute(UserPage);
