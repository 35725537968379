import { Link } from 'react-router-dom';
import Routes from '../routes';
import { Typography } from '@material-ui/core';
import { formatDate } from '../../utils/date-time-utils';

const OrganizerColumns = [
  { field: 'companyName', headerName: 'Company Name', sortable: false, width: 200 },
  { field: 'pointOfContact', headerName: 'Point of Contact', sortable: false, width: 200 },
  { field: 'phoneNumber', headerName: 'Phone Number', sortable: false, width: 155 },
  { field: 'corporateEmail', headerName: 'Email', sortable: false, width: 300 },
  {
    field: 'link',
    headerName: 'View',
    renderCell: function TableLink(params) {
      return (
        <Typography color={'primary'} component={Link} to={Routes.organizer(params.row.id)}>
          Details
        </Typography>
      );
    },
    sortable: false,
    width: 110,
  },
  {
    field: 'officeAddress.country',
    headerName: 'Country',
    valueGetter: function render(params) {
      return params.row.officeAddress.country;
    },
    sortable: false,
    width: 150,
  },
  {
    field: 'officeAddress.state',
    headerName: 'State',
    valueGetter: function render(params) {
      return params.row.officeAddress.state;
    },
    sortable: false,
    width: 150,
  },
  {
    field: 'officeAddress.city',
    headerName: 'City',
    valueGetter: function render(params) {
      return params.row.officeAddress.city;
    },
    sortable: false,
    width: 150,
  },
  {
    field: 'officeAddress.postalCode',
    headerName: 'Pin Code',
    valueGetter: function render(params) {
      return params.row.officeAddress.postalCode;
    },
    sortable: false,
    width: 100,
  },
  {
    field: 'createdAt',
    headerName: 'Created',
    width: 100,
    sortable: false,
    valueFormatter: ({ value }) => formatDate(value),
  },
  {
    field: 'updatedAt',
    headerName: 'Updated',
    width: 100,
    sortable: false,
    valueFormatter: ({ value }) => formatDate(value),
  },
  { field: 'panNumber', headerName: 'PAN', sortable: false, width: 150 },
  { field: 'gstin', headerName: 'GSTIN', sortable: false, width: 150 },
  {
    field: 'officeAddress.formattedAddress',
    headerName: 'Address',
    valueGetter: function render(params) {
      return params.row.officeAddress.formattedAddress;
    },
    sortable: false,
    width: 500,
  },
  { field: 'id', headerName: 'ID', hide: true, width: 300 },
];

export default OrganizerColumns;
