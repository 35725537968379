import Snackbar from '@material-ui/core/Snackbar';
import React from 'react';
import Alert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { notificationsSelector, notificationTimeout } from '../../redux/notifications/ducks';

/**
 * Site-wide notification module coupled with redux.
 * @return {JSX.Element}
 * @constructor
 */
const Notification = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(notificationsSelector);

  const handleClose = () => {
    dispatch(notificationTimeout());
  };

  return (
    <Snackbar open={notifications.length !== 0} autoHideDuration={5000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={notifications[0]?.type}>
        {notifications[0]?.message}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
