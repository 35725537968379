import { IconButton, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { Add, Edit, Delete } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Export from '../components/common/Export';
import SEO from '../components/common/SEO';
import withOrganizerAuthenticatedRoute from '../components/hoc/withOrganizerAuthenticatedRoute';
import Layout from '../components/navigation/Layout';
import Routes from '../constants/routes';
import { upcomingEventsSelector } from '../redux/events/ducks';
import { fetchAllUpcomingEvents, fetchAllUpcomingEventsByOrganizer } from '../services/events-service';
import EventColumns from '../constants/grids/events_columns';
import { userClaimsSelector } from '../redux/users/ducks';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';

const UpcomingEventsTablePage = () => {
  const { data, loading } = useSelector(upcomingEventsSelector);

  const [selected, setSelected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  return (
    <Layout>
      <SEO title={'Upcoming Events'} />
      <TableToolbar selected={selected} />
      <DataGrid
        style={{ height: 800, width: 'full' }}
        columns={EventColumns}
        // disableColumnMenu
        // hideFooterPagination
        checkboxSelection
        disableSelectionOnClick
        // hideFooterSelectedRowCount
        loading={loading}
        onPageSizeChange={(pageSize) => setRowsPerPage(pageSize)}
        onSelectionModelChange={(selected) => setSelected(selected)}
        pageSize={rowsPerPage}
        rows={data}
        rowsPerPageOptions={[10, 25, 50, 75, 100]}
        scrollbarSize={70}
        selectionModel={selected}
      />
    </Layout>
  );
};

const TableToolbar = ({ selected }) => {
  const history = useHistory();
  const claims = useSelector(userClaimsSelector);

  const deleteEvent = async (eventId) => {
    try {
      // Delete the event from Firebase Firestore
      await firebase.firestore().collection('events').doc(eventId).delete();

      // Delete the event image from Firebase Storage
      const storageRef = firebase.storage().ref();
      const eventImageRef = storageRef.child(`events/${eventId}/image.jpg`);
      await eventImageRef.delete();

      console.log('Event deleted successfully.');
    } catch (error) {
      console.error('Error deleting event:', error);
    }
  };

  return (
    <Toolbar style={{ marginBottom: 8, padding: 0 }}>
      <Typography style={{ flex: '1 1 100%' }} variant={'h3'}>
        Upcoming Events
      </Typography>

      <Tooltip title={'Add'}>
        <span>
          <IconButton
            aria-label={'add'}
            disabled={Boolean(selected.length)}
            onClick={() => history.push(Routes.eventForm('new'))}
          >
            <Add />
          </IconButton>
        </span>
      </Tooltip>

      <Tooltip title={'Edit'}>
        <span>
          <IconButton
            aria-label={'edit'}
            disabled={selected.length !== 1}
            onClick={() => history.push(Routes.eventForm(selected[0]))}
          >
            <Edit />
          </IconButton>
        </span>
      </Tooltip>

      <Tooltip title={'Delete'}>
        <span>
          <IconButton aria-label={'delete'} disabled={selected.length !== 1} onClick={() => deleteEvent(selected[0])}>
            <Delete />
          </IconButton>
        </span>
      </Tooltip>

      <Tooltip title={'Export to Excel'}>
        <span>
          <Export
            filename={'upcoming-events'}
            fetchData={() =>
              process.env.REACT_APP_BUILD_TYPE === 'ADMIN'
                ? fetchAllUpcomingEvents()
                : fetchAllUpcomingEventsByOrganizer(claims.organizer)
            }
          />
        </span>
      </Tooltip>
    </Toolbar>
  );
};

TableToolbar.propTypes = { selected: PropTypes.array.isRequired };

export default withOrganizerAuthenticatedRoute(UpcomingEventsTablePage);
