import { formatDateTime } from '../../utils/date-time-utils';

const TicketColumns = [
  {
    field: 'name',
    headerName: 'Name',
    width: 200,
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 200,
  },
  {
    field: 'price',
    headerName: 'Price',
    valueFormatter: ({ value }) => `₹${value}`,
    width: 100,
  },
  {
    field: 'totalTickets',
    headerName: 'Total Tickets',
    width: 150,
  },
  {
    field: 'soldTickets',
    headerName: 'Sold Tickets',
    width: 140,
  },
  {
    field: 'datetime',
    headerName: 'Time',
    width: 200,
    valueFormatter: ({ value }) => formatDateTime(value),
  },
  { field: 'id', headerName: 'Ticket ID', hide: true, width: 200 },
];

export default TicketColumns;
