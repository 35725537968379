import { Grid, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import SEO from 'components/common/SEO';
import Layout from 'components/navigation/Layout';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { setNotification } from 'redux/notifications/ducks';
import { fetchOrganizerById, fetchOrganizerEvents } from 'services/orgaizers-service';
import Export from '../components/common/Export';
import withAdminAuthenticatedRoute from '../components/hoc/withAdminAuthenticatedRoute';
import EventColumns from '../constants/grids/events_columns';
import { formatDate } from '../utils/date-time-utils';
import PageNotFound from './404';
import LoadingPage from './loading_page';

const OrganizerPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [organizer, setOrganizer] = useState(null);

  useEffect(() => {
    if (id) {
      (async () => {
        try {
          setLoading(true);
          const organizer = await fetchOrganizerById(id);
          setOrganizer(organizer);
        } catch (e) {
          console.error(e);
          dispatch(setNotification(e.message));
          setError(true);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [dispatch, id]);

  if (loading) {
    return <LoadingPage />;
  } else if (error) {
    return <PageNotFound />;
  }

  return (
    <Layout>
      <SEO title={organizer.companyName} />
      <Typography paragraph variant='h2'>
        Organizer Details
      </Typography>

      <Grid container spacing={4}>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Typography gutterBottom style={{ fontWeight: 'bold' }}>
              Company Name
            </Typography>
            <Typography>{organizer.companyName}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography gutterBottom style={{ fontWeight: 'bold' }}>
              Corporate Email
            </Typography>
            <Typography>{organizer.corporateEmail}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography gutterBottom style={{ fontWeight: 'bold' }}>
              Contact Name
            </Typography>
            <Typography>{organizer.pointOfContact}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography gutterBottom style={{ fontWeight: 'bold' }}>
              PAN
            </Typography>
            <Typography>{organizer.panNumber}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography gutterBottom style={{ fontWeight: 'bold' }}>
              GSTIN
            </Typography>
            <Typography>{organizer.GSTIN}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography gutterBottom style={{ fontWeight: 'bold' }}>
              Sign Up
            </Typography>
            <Typography>{formatDate(organizer.createdAt)}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom style={{ fontWeight: 'bold' }}>
              Office Address
            </Typography>
            <Typography>{organizer.officeAddress.formattedAddress}</Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Toolbar style={{ marginBottom: 8, padding: 0, width: '100%' }}>
            <Typography style={{ flex: '1 1 100%' }} variant={'h3'}>
              Events
            </Typography>

            <Tooltip title={'Export to Excel'} style={{ marginLeft: 'auto' }}>
              <span>
                <Export filename={'events'} fetchData={() => fetchOrganizerEvents(id, true)} />
              </span>
            </Tooltip>
          </Toolbar>
          <DataGrid
            autoHeight
            columns={EventColumns}
            disableColumnMenu
            disableSelectionOnClick
            hideFooterSelectedRowCount
            // onPageChange={(params, _) => setPage(params.page + 1)}
            // onPageSizeChange={(params) => setRowsPerPage(params.pageSize)}
            pageSize={100}
            rows={organizer.events}
            rowsPerPageOptions={[100]}
            scrollbarSize={70}
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default withAdminAuthenticatedRoute(OrganizerPage);
