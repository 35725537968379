import React, { useState } from 'react';
import { GetApp as GetAppIcon } from '@material-ui/icons';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { CircularProgress, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import { utils, write } from 'xlsx';

const useStyles = makeStyles((theme) => ({
  button: {
    height: 50,
    color: theme.palette.text.primary,
  },
}));

const Export = ({ filename, fetchData }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    const fetchedData = await fetchData();

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const worksheet = utils.json_to_sheet(fetchedData);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, filename + fileExtension);
    setLoading(false);
  };

  return loading ? (
    <CircularProgress className={classes.button} size={40} thickness={5} />
  ) : (
    <IconButton className={classes.button} disabled={loading} onClick={() => getData()} variant={'contained'}>
      <GetAppIcon />
    </IconButton>
  );
};

Export.propTypes = {
  filename: PropTypes.string,
  fetchData: PropTypes.func.isRequired,
};

Export.defaultProps = {
  filename: 'data',
};

export default Export;
