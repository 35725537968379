import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { formatDate } from '../../utils/date-time-utils';
import Routes from '../routes';

const UserColumns = [
  { field: 'firstName', headerName: 'First Name', sortable: false, width: 200 },
  { field: 'lastName', headerName: 'Last Name', sortable: false, width: 200 },
  { field: 'location', headerName: 'Location', sortable: false, width: 150 },
  { field: 'email', headerName: 'Email', sortable: false, width: 300 },
  { field: 'phoneNumber', headerName: 'Phone Number', sortable: false, width: 155 },
  {
    field: 'link',
    headerName: 'View',
    sortable: false,
    renderCell: function TableLink(params) {
      return (
        <Typography color={'primary'} component={Link} to={Routes.user(params.row.id)}>
          Details
        </Typography>
      );
    },
    width: 75,
  },
  {
    field: 'createdAt',
    headerName: 'Created',
    width: 100,
    sortable: false,
    valueFormatter: ({ value }) => formatDate(value),
  },
  {
    field: 'updatedAt',
    headerName: 'Updated',
    width: 100,
    sortable: false,
    valueFormatter: ({ value }) => formatDate(value),
  },
  // { field: 'hasPasswordAuth', headerName: 'Password', sortable: false, width: 110 },
  { field: 'id', headerName: 'ID', sortable: false, width: 280 },
  { field: 'customerId', headerName: 'Razorpay ID', sortable: false, width: 280 },
  {
    field: 'categories',
    headerName: 'Categories',
    valueFormatter: ({ value }) => value.join(', '),
    sortable: false,
    width: 500,
  },
];

export default UserColumns;
