import { combineReducers } from 'redux';
import { eventsReducer } from './events/ducks';
import { notificationsReducer } from './notifications/ducks';
import { organizersReducer } from './organizers/ducks';
import { usersReducer } from './users/ducks';

const rootReducer = combineReducers({
  notifications: notificationsReducer,
  users: usersReducer,
  events: eventsReducer,
  organizers: organizersReducer,
});

export default rootReducer;
