import { Button, Container, Grid, TextField, Typography } from '@material-ui/core';
import withOrganizerAuthenticatedRoute from 'components/hoc/withOrganizerAuthenticatedRoute';
import OrganizerFields from 'constants/OrganizerFields';
import { useFormik } from 'formik';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userProfileSelector } from 'redux/users/ducks';
import { getValidationSchema } from 'utils/formik-utils';
import { setNotification } from '../redux/notifications/ducks';
import { updateOrganizer } from '../services/orgaizers-service';
import Layout from '../components/navigation/Layout';
import LoadingPage from './loading_page';
import Routes from '../constants/routes';
import { useHistory } from 'react-router';

const initialValues = OrganizerFields.reduce((curr, { id, fields }) => {
  if (id === 'officeAddress') {
    return fields.reduce(
      (acc, { id: officeId }) => ({ ...acc, officeAddress: { ...acc.officeAddress, [officeId]: '' } }),
      curr
    );
  }
  return { ...curr, [id]: id === 'contactNumber' ? '+91' : '' };
}, {});

const fieldsForValidation = OrganizerFields.map(({ id }) => {
  if (id === 'officeAddress') {
    return `officeAddress.${id}`;
  }
  return id;
});

const ProfilePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(true);

  const profile = useSelector(userProfileSelector);

  const formik = useFormik({
    initialValues,
    validationSchema: getValidationSchema(fieldsForValidation, 'organizer'),
    onSubmit: async (values) => {
      try {
        await updateOrganizer(profile.id, values);
        dispatch(setNotification('Profile updated', 'success'));
        history.push(Routes.UPCOMING_EVENTS);
      } catch (error) {
        console.error(error);
        dispatch(setNotification('Failed to update. Please try again.'));
      }
    },
  });

  useEffect(() => {
    if (profile && profile.companyName) {
      const values = {};
      OrganizerFields.forEach(({ id, fields }) => {
        if (id === 'officeAddress') {
          values.officeAddress = {};
          fields.forEach(({ id: subId }) => {
            values.officeAddress[subId] = profile.officeAddress[subId];
          });
        } else {
          values[id] = profile[id];
        }
      });
      formik.setValues(values);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <Layout>
      <Container>
        <Typography align={'center'} style={{ marginBottom: 40 }} variant={'h2'}>
          Profile
        </Typography>

        <form name={'Seller Profile Form'} onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            {OrganizerFields.map(({ id, label, fields, required }) =>
              id === 'officeAddress' ? (
                <Fragment key={id}>
                  <Typography paragraph style={{ marginTop: 40 }} variant='h3'>
                    Office Address
                  </Typography>
                  <Grid item container xs={12} spacing={2}>
                    {fields.map(({ id, label, required }) => (
                      <Grid item xs={12} sm={id === 'formattedAddress' ? 12 : 6} key={id}>
                        <TextField
                          fullWidth
                          id={`officeAddress.${id}`}
                          name={`officeAddress.${id}`}
                          // error={formik.touched.officeAddress[id] && Boolean(formik.errors.officeAddress[id])}
                          // helperText={formik.touched.officeAddress[id] && formik.errors.officeAddress[id]}
                          value={formik.values.officeAddress[id]}
                          onChange={formik.handleChange}
                          required={Boolean(required)}
                          label={label}
                          variant={'outlined'}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Fragment>
              ) : (
                <Grid item xs={12} sm={6} key={id}>
                  <TextField
                    fullWidth
                    id={id}
                    name={id}
                    error={formik.touched[id] && Boolean(formik.errors[id])}
                    helperText={formik.touched[id] && formik.errors[id]}
                    value={formik.values[id]}
                    onChange={formik.handleChange}
                    required={Boolean(required)}
                    label={label}
                    variant={'outlined'}
                  />
                </Grid>
              )
            )}
          </Grid>
          <Button
            color={'primary'}
            fullWidth
            style={{ display: 'block', margin: '2.5rem auto 0', maxWidth: 300 }}
            type={'submit'}
            variant={'contained'}
          >
            Save
          </Button>
        </form>
      </Container>
    </Layout>
  );
};

export default withOrganizerAuthenticatedRoute(ProfilePage);
