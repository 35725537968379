import { IconButton, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { Refresh } from '@material-ui/icons';
import withOrganizerAuthenticatedRoute from 'components/hoc/withOrganizerAuthenticatedRoute';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Export from '../components/common/Export';
import SEO from '../components/common/SEO';
import Layout from '../components/navigation/Layout';
import EventColumns from '../constants/grids/events_columns';
import { fetchPastEvents, pastEventsSelector } from '../redux/events/ducks';
import { userClaimsSelector } from '../redux/users/ducks';
import { fetchAllPastEvents, fetchAllPastEventsByOrganizer } from '../services/events-service';

const PastEventsTablePage = () => {
  const dispatch = useDispatch();

  const { canFetch, data, loading } = useSelector(pastEventsSelector);

  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(1);

  const rowsCount = data.length + (canFetch ? rowsPerPage : 0);

  const setState = (refresh = false) => {
    if (!refresh && canFetch) {
      const limit = rowsPerPage; // Fetch one page at a time
      dispatch(fetchPastEvents(true, limit));
    } else {
      dispatch(fetchPastEvents(false, rowsPerPage));
      setPage(1);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(setState, [dispatch, page, canFetch, rowsPerPage]);

  return (
    <Layout>
      <SEO title={'Past Events'} />
      <TableToolbar setState={setState} />
      <DataGrid
        style={{ height: 800, width: '100%' }}
        columns={EventColumns}
        disableColumnMenu
        // hideFooterPagination
        disableSelectionOnClick
        hideFooterSelectedRowCount
        loading={loading}
        onPageChange={(page) => setPage(page)}
        onPageSizeChange={(pageSize) => setRowsPerPage(pageSize)}
        pageSize={rowsPerPage}
        rows={data}
        rowCount={rowsCount}
        rowsPerPageOptions={[10, 25, 50, 75, 100]}
        scrollbarSize={70}
      />
    </Layout>
  );
};

const TableToolbar = ({ setState }) => {
  const claims = useSelector(userClaimsSelector);

  return (
    <Toolbar style={{ marginBottom: 8, padding: 0 }}>
      <Tooltip title={'Refresh'}>
        <span>
          <IconButton aria-label={'refresh'} onClick={() => setState(true)}>
            <Refresh />
          </IconButton>
        </span>
      </Tooltip>

      <Typography style={{ flex: '1 1 100%' }} variant={'h3'}>
        Past Events
      </Typography>

      <Tooltip title={'Export to Excel'}>
        <span>
          <Export
            filename={'past-events'}
            fetchData={() =>
              process.env.REACT_APP_BUILD_TYPE === 'ADMIN'
                ? fetchAllPastEvents()
                : fetchAllPastEventsByOrganizer(claims.organizer)
            }
          />
        </span>
      </Tooltip>
    </Toolbar>
  );
};

TableToolbar.propTypes = {
  setState: PropTypes.func.isRequired,
};

export default withOrganizerAuthenticatedRoute(PastEventsTablePage);
