import { createTheme, responsiveFontSizes } from '@material-ui/core';

const primary = '#DD1745';
const secondary = '#212121';
const textPrimary = `#fafafa`;
const textSecondary = `#bdbdbd`;
const white = `#FFF`;

const theme = responsiveFontSizes(
  createTheme({
    overrides: {
      MuiSelect: {
        root: {
          fontSize: '14px !important',
        },
      },
      MuiMenuItem: {
        root: {
          'fontSize': '14px !important',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
      MuiFormHelperText: {
        root: {
          fontSize: '14px',
        },
      },
      MuiInputBase: {
        root: {
          fontSize: '16px !important',
        },
      },
      MuiInputLabel: {
        root: {
          fontSize: '16px',
        },
      },
      MuiButton: {
        containedPrimary: {
          backgroundColor: white,
          background: 'linear-gradient(91.63deg, #DD1745 -27.48%, #033A69 121.91%)',
          borderRadius: '8px !important',
        },
        containedSecondary: {
          background:
            'linear-gradient(90.23deg, rgba(255, 255, 255, 0.63) 0%, rgba(255, 255, 255, 0.480703) 47.4%, rgba(255, 255, 255, 0.315) 100%) !important',
          backdropFilter: 'blur(100px)',
          borderRadius: '8px !important',
        },
      },
      MuiDialog: {
        root: {
          color: '#FFF !important',
        },
        paper: {
          background:
            'linear-gradient(90.23deg, rgba(255, 255, 255, 0.63) 0%, rgba(255, 255, 255, 0.480703) 47.4%, rgba(255, 255, 255, 0.315) 100%)',
          backdropFilter: 'blur(100px)',
          borderRadius: '32px',
        },
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: primary,
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    palette: {
      type: 'dark',
      background: {
        default: '#121212',
        paper: '#212121',
      },
      primary: {
        main: primary,
        contrastText: white,
      },
      secondary: {
        main: secondary,
        contrastText: white,
      },
      text: {
        primary: textPrimary,
        secondary: textSecondary,
      },
    },
    typography: {
      fontFamily: `'Poppins', sans-serif`,
      h1: {
        color: textPrimary,
        fontSize: 40,
        fontWeight: 500,
        // lineHeight: '1.25',
      },
      h2: {
        color: textPrimary,
        fontSize: 32,
        fontWeight: 500,
      },
      h3: {
        color: textPrimary,
        fontSize: 24,
        fontWeight: 500,
        lineHeight: 1.5,
      },
      body1: {
        color: textSecondary,
        fontSize: 16,
        fontWeight: 400,
      },
      body2: {
        color: textSecondary,
        fontSize: 14,
        fontWeight: 400,
      },
      button: {
        fontSize: 16,
      },
    },
  })
);

export default theme;
