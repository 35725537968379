import { Link as MuiLink, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { formatDate } from '../../utils/date-time-utils';
import { CategoriesJson } from '../categories';
import { LanguagesJson } from '../languages';
import Routes from '../routes';

// Calculate the duration between two dates in days, months, and years
const calculateDuration = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const timeDifference = end - start;
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

  const startYear = start.getFullYear();
  const startMonth = start.getMonth();
  const startDateOfMonth = start.getDate();

  const endYear = end.getFullYear();
  const endMonth = end.getMonth();
  const endDateOfMonth = end.getDate();

  let years = endYear - startYear;
  let months = endMonth - startMonth;
  let days = endDateOfMonth - startDateOfMonth;

  if (days < 0) {
    months--;
    const prevMonthLastDay = new Date(endYear, endMonth, 0).getDate();
    days += prevMonthLastDay;
  }

  if (months < 0) {
    years--;
    months += 12;
  }

  return { years, months, days };
};

const formatDuration = (years, months, days) => {
  if (years === 0 && months === 0 && days === 0) {
    return '0 days';
  }

  const yearStr = years > 0 ? `${years} year${years > 1 ? 's' : ''}` : '';
  const monthStr = months > 0 ? `${months} month${months > 1 ? 's' : ''}` : '';
  const dayStr = days > 0 ? `${days} day${days > 1 ? 's' : ''}` : '';

  const durationParts = [yearStr, monthStr, dayStr].filter((part) => part !== '');

  return durationParts.join(' ');
};

const EventColumns = [
  { field: 'name', headerName: 'Name', sortable: false, width: 200 },
  { field: 'featured', headerName: 'Featured', sortable: false, width: 120 },
  { field: 'verified', headerName: 'Verified', sortable: false, width: 110 },
  {
    field: 'link',
    headerName: 'View',
    renderCell: function TableLink(params) {
      return (
        <Typography color={'primary'} component={Link} to={Routes.event(params.row.id)}>
          View
        </Typography>
      );
    },
    sortable: false,
    width: 90,
  },
  // {
  //   field: 'preview',
  //   headerName: 'Preview',
  //   renderCell: function TableLink(params) {
  //     return (
  //       <Typography color={'primary'} component={MuiLink} href={Routes.eventPreview(params.row.id)} target={'_blank'}>
  //         Preview
  //       </Typography>
  //     );
  //   },
  //   sortable: false,
  //   width: 110,
  // },
  {
    field: 'startDate',
    headerName: 'Start Date',
    width: 130,
    sortable: false,
    valueFormatter: ({ value }) => formatDate(value),
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    width: 130,
    sortable: false,
    valueFormatter: ({ value }) => formatDate(value),
  },
  { field: 'soldOut', headerName: 'Sold Out', sortable: false, width: 120 },
  {
    field: 'minPrice',
    headerName: 'Amount',
    valueFormatter: ({ value }) => `₹${value}`,
    sortable: false,
    width: 110,
  },
  {
    field: 'artist.name',
    headerName: 'Artist',
    valueGetter: function render(params) {
      return params.row.artist.name;
    },
    sortable: false,
    width: 200,
  },
  {
    field: 'location.city',
    headerName: 'City',
    valueGetter: function render(params) {
      return params.row.location.city;
    },
    sortable: false,
    width: 150,
  },
  {
    field: 'createdAt',
    headerName: 'Created',
    width: 110,
    sortable: false,
    valueFormatter: ({ value }) => formatDate(value),
  },
  {
    field: 'updatedAt',
    headerName: 'Updated',
    width: 120,
    sortable: false,
    valueFormatter: ({ value }) => formatDate(value),
  },
  {
    field: 'languages',
    headerName: 'Languages',
    width: 300,
    sortable: false,
    valueFormatter: ({ value }) => value.map((lang) => LanguagesJson[lang]).join(', '),
  },
  {
    field: 'categories',
    headerName: 'Categories',
    width: 500,
    sortable: false,
    valueFormatter: ({ value }) => value.map((cat) => CategoriesJson[cat]).join(', '),
  },
  {
    field: 'eventAddress',
    headerName: 'Event Address',
    width: 1000,
    sortable: false,
    valueGetter: function render(params) {
      return params.row.location.formattedAddress; // Modify this based on the actual property name
    },
  },
  {
    field: 'eventDuration',
    headerName: 'Duration',
    width: 200,
    sortable: false,
    valueGetter: function render(params) {
      const { years, months, days } = calculateDuration(
        formatDate(params.row.startDate),
        formatDate(params.row.endDate)
      );

      return formatDuration(years, months, days);
    },
  },

  { field: 'id', headerName: 'ID', hide: true, width: 300 },
];

export default EventColumns;
