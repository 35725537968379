import { formatDateTime } from './date-time-utils';

export const formatBookingsForExport = (bookings) =>
  bookings
    .map((booking) => {
      const bookings = [];

      booking.createdAt = formatDateTime(booking.createdAt);
      booking.eventId = booking.event.id;
      booking.event = booking.event.name;
      booking.time = formatDateTime(booking.time);
      booking.userEmail = booking.user.email;
      booking.userName = booking.user.firstName;
      booking.userPhoneNumber = booking.user.phoneNumber;
      booking.bloodGroup = booking.user.bloodGroup;
      booking.country = booking.user.country;
      booking.emergencyContactPerson = booking.user.emergencyContactPerson;
      booking.emergencyContactNumber = booking.user.emergencyContactNumber;
      booking.tshirtSize = booking.user.tshirtSize;
      booking.company = booking.user.company;

      delete booking.eventLogs;
      delete booking.reminders;
      delete booking.updatedAt;
      delete booking.user;

      booking.tickets.forEach((ticket) => {
        const temp = { ...booking, ...formatTicketForExport(ticket) };
        delete temp.tickets;
        bookings.push(temp);
      });
      return bookings;
    })
    .flat();

const formatTicketForExport = (ticket) => ({
  ticketName: ticket.name,
  ticketType: ticket.type,
  ticketTime: formatDateTime(ticket.datetime),
  ticketPrice: ticket.price,
  ticketQuantity: ticket.quantity,
  ticketStartingNumber: ticket.ticketNumber,
});
