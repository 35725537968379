export const Categories = [
  { name: 'Artist', key: 'ARTIST' },
  { name: 'Arts', key: 'ARTS' },
  { name: 'Comedy', key: 'COMEDY' },
  { name: 'Concerts', key: 'CONCERTS' },
  { name: 'Educative', key: 'EDUCATIVE' },
  { name: 'Events', key: 'EVENTS' },
  { name: 'Experience', key: 'EXPERIENCE' },
  { name: 'Kids', key: 'KIDS' },
  { name: 'Movies', key: 'MOVIES' },
  { name: 'Music', key: 'MUSIC' },
  { name: 'Online Games', key: 'ONLINE-GAMES' },
  { name: 'Quizzes', key: 'QUIZZES' },
  { name: 'Sports', key: 'SPORTS' },
  { name: 'Talks', key: 'TALKS' },
  { name: 'Theatre and Drama', key: 'THEATRE-AND-DRAMA' },
  { name: 'Virtual Events', key: 'VIRTUAL-EVENTS' },
  { name: 'Workshops', key: 'WORKSHOPS' },
];

const CategoriesJson = {};
Categories.forEach(({ name, key }) => {
  CategoriesJson[key] = name;
});

export { CategoriesJson };
export default Categories;
