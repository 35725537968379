const Languages = [
  {
    label: 'English',
    value: 'ENGLISH',
  },
  {
    label: 'Hindi',
    value: 'HINDI',
  },
  {
    label: 'Kannada',
    value: 'KANNADA',
  },
  {
    label: 'Tamil',
    value: 'TAMIL',
  },
  {
    label: 'Malayalam',
    value: 'MALAYALAM',
  },
];

const LanguagesJson = {};
Languages.forEach(({ label, value }) => {
  LanguagesJson[value] = label;
});

export { LanguagesJson };
export default Languages;
