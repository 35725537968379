const TicketVerifiersColumns = [
  {
    field: 'name',
    headerName: 'Name',
    valueGetter: ({ row }) => `${row.firstName} ${row.lastName}`,
    width: 300,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 300,
  },
  {
    field: 'phoneNumber',
    headerName: 'Phone Number',
    width: 200,
  },
  { field: 'id', headerName: 'ID', hide: true, width: 200 },
];

export default TicketVerifiersColumns;
