import { fork } from '@redux-saga/core/effects';
import { eventsSaga } from './events/sagas';
import { sellersSaga } from './organizers/sagas';
import { usersSaga } from './users/sagas';

function* rootSaga() {
  yield fork(usersSaga);
  yield fork(eventsSaga);
  yield fork(sellersSaga);
}

export default rootSaga;
