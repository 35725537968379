import { Link as MuiLink, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { formatDate, formatDateTime } from '../../utils/date-time-utils';
import Routes from '../routes';

const BookingColumns = [
  { field: 'id', headerName: 'Order ID', width: 200 },
  {
    field: 'user.firstName',
    headerName: 'Name',
    valueGetter: ({ row }) => row.user.firstName + ' ' + row.user.lastName,
    width: 200,
  },
  {
    field: 'user.phoneNumber',
    headerName: 'Phone Number',
    valueGetter: ({ row }) => row.user.phoneNumber,
    width: 200,
  },
  {
    field: 'user.email',
    headerName: 'Email',
    valueGetter: ({ row }) => row.user.email,
    width: 300,
  },
  // {
  //   field: 'user.id',
  //   headerName: 'User',
  //   renderCell: function TableLink(params) {
  //     return (
  //       <Typography color={'primary'} component={Link} to={Routes.user(params.row.user.id)}>
  //         View
  //       </Typography>
  //     );
  //   },
  //   sortable: false,
  //   valueGetter: ({ row }) => row.user.id,
  //   width: 100,
  // },
  {
    field: 'amount',
    headerName: 'Amount',
    valueFormatter: ({ value }) => `₹${value}`,
    width: 140,
  },
  {
    field: 'time',
    headerName: 'Time',
    valueFormatter: ({ value }) => formatDateTime(value),
    width: 150,
  },
  {
    field: 'paymentStatus',
    headerName: 'Status',
    sortable: false,
    width: 200,
  },
  {
    field: 'totalTickets',
    headerName: 'Total Tickets',
    sortable: false,
    width: 150,
  },
  {
    field: 'tickets',
    headerName: 'Tickets',
    valueFormatter: ({ value }) => value.map(({ type, quantity }) => `${quantity} ${type}`).join(', '),
    sortable: false,
    width: 150,
  },
  {
    field: 'bloodGroup',
    headerName: 'Blood Group',
    valueGetter: ({ row }) => row.user?.bloodGroup,
    sortable: false,
    width: 150,
  },
  {
    field: 'company',
    headerName: 'Company',
    valueGetter: ({ row }) => row.user?.company,
    sortable: false,
    width: 150,
  },
  {
    field: 'country',
    headerName: 'Country',
    valueGetter: ({ row }) => row.user?.country,
    sortable: false,
    width: 150,
  },
  {
    field: 'tshirtSize',
    headerName: 'T-Shirt Size',
    valueGetter: ({ row }) => row.user?.tshirtSize,
    sortable: false,
    width: 150,
  },
  {
    field: 'emergencyContactPerson',
    headerName: 'Emergency Person',
    valueGetter: ({ row }) => row.user?.emergencyContactPerson,
    sortable: false,
    width: 150,
  },
  {
    field: 'emergencyContactNumber',
    headerName: 'Emergency Number',
    valueGetter: ({ row }) => row.user?.emergencyContactNumber,
    sortable: false,
    width: 150,
  },
  {
    field: 'time',
    headerName: 'Event Date',
    width: 200,
    valueFormatter: ({ value }) => formatDate(value),
    sortable: false,
  },
  {
    field: 'createdAt',
    headerName: 'Created',
    width: 110,
    sortable: false,
    valueFormatter: ({ value }) => formatDate(value),
  },
];

export default BookingColumns;
