import { addDays } from 'date-fns';
import * as Yup from 'yup';

export const getValidationByKey = (key) => {
  switch (key) {
    case 'event_about':
      return Yup.string().required('About event required');
    case 'event_categories':
      return Yup.array().of(Yup.string()).min(1, 'Choose at least one category').required('Event categories required');
    case 'event_languages':
      return Yup.array().of(Yup.string()).min(1, 'Choose at least one language').required('Event languages required');
    case 'event_information':
      return Yup.string().required('Event information required');
    case 'event_name':
      return Yup.string().required('Event name required');
    case 'event_language':
      return Yup.string().required('Event language required');
    case 'event_artist':
      return Yup.object({
        about: Yup.string().required('Artist about required'),
        name: Yup.string().required('Artist name required'),
      });
    case 'event_location':
      return Yup.object({
        fullAddress: Yup.string().required('Enter event location'),
        city: Yup.string().required('Enter event city'),
        state: Yup.string().required('Enter event state'),
        country: Yup.string().required('Enter event country'),
        zipcode: Yup.number().required('Enter event zipcode'),
      });
    case 'event_tickets':
      return Yup.array()
        .of(
          Yup.object({
            name: Yup.string().required('Ticket name required'),
            description: Yup.string().required('Ticket description required'),
            type: Yup.string().required('Ticket type required'),
            totalTickets: Yup.number()
              .min(1, 'Total tickets must be greater than 0.')
              .required('Total tickets required'),
            price: Yup.number().min(0, 'Ticket price must be greater than 0.').required('Ticket price required.'),
            datetime: Yup.date()
              .min(addDays(new Date(), 0), 'Datetime must be at least 24 hours ahead.')
              .required('Entry time required.'),
          })
        )
        .min(1, 'Minimum 1 ticket is required.');
    case 'event_featured':
    case 'event_verified':
      return Yup.boolean();

    // Organizer
    case 'organizer_companyName':
      return Yup.string().required('Company name required');
    case 'organizer_pointOfContact':
      return Yup.string().required('Contact name required');
    case 'organizer_panNumber':
      return Yup.string().length(10, 'Incorrect PAN length');
    case 'organizer_phoneNumber':
      return Yup.string().matches(/^\+91\d{10}$/, 'Invalid phone number');
    case 'organizer_corporateEmail':
      return Yup.string().email('Invalid email').required('Corporate email required');
    case 'organizer_officeAddress.postalCode':
      return Yup.number().length(6, 'Incorrect pin code length');

    // case 'organizer_officeAddress.formattedAddress':
    // case 'organizer_officeAddress.city':
    // case 'organizer_officeAddress.country':
    // case 'organizer_officeAddress.state':

    case 'phoneNumber':
      return Yup.number()
        .min(1000000000, 'Please enter a valid phone number')
        .max(9999999999, 'Please enter a valid phone number')
        .required('Please enter a phone number');

    default:
      return Yup.string();
  }
};

export const getValidationSchema = (keys, prefix) => {
  prefix = prefix ? prefix + '_' : '';
  const schema = {};
  keys.forEach((key) => {
    schema[key] = getValidationByKey(prefix + key);
  });
  return Yup.object(schema);
};
