// import { Status, Wrapper } from '@googlemaps/react-wrapper';
// import { Box, TextField, Typography } from '@material-ui/core';
// import LocationOnIcon from '@material-ui/icons/LocationOn';
// import Autocomplete from '@material-ui/lab/Autocomplete';
// import { debounce } from 'lodash-es';
// import PropTypes from 'prop-types';
// import { useCallback, useEffect, useRef, useState } from 'react';
// import { getLatLongFromLocation, getLocationFromGeocoderResponse } from '../../utils/location-utils';

// const GoogleMapsComponent = ({ location, setLocation }) => {
//   const autoCompleteServiceRef = useRef();
//   const placesDetailsRef = useRef();
//   const mapDivRef = useRef();
//   const mapRef = useRef();
//   const markerRef = useRef();
//   const geocoderRef = useRef();
//   const initialCoordinates = useRef(
//     location
//       ? { lat: location.coordinates.latitude, lng: location.coordinates.longitude }
//       : { lat: 12.9472235, lng: 77.5844803 }
//   );
//   const sessionToken = useRef();

//   const [init, setInit] = useState(false);
//   const [options, setOptions] = useState([]);

//   const fetchPredictions = (input) => {
//     if (input) {
//       autoCompleteServiceRef.current.getPlacePredictions({ input, sessionToken: sessionToken.current }, (results) => {
//         setOptions(results || []);
//       });
//     } else {
//       setOptions([]);
//     }
//   };

//   const setLocationFromPlaceId = (placeId) => {
//     if (placeId && placesDetailsRef.current) {
//       placesDetailsRef.current.getDetails(
//         {
//           placeId,
//           sessionToken: sessionToken.current,
//         },
//         (res) => {
//           const address = getLocationFromGeocoderResponse(res);
//           setMarkerPosition(res.geometry.location);
//           mapRef.current.panTo(res.geometry.location);
//           mapRef.current.setZoom(20);
//           sessionToken.current = new google.maps.places.AutocompleteSessionToken();
//           setLocation(address);
//         }
//       );
//     }
//   };

//   const setLocationFromCoordinates = useCallback(
//     debounce(
//       async (coordinates) => {
//         if (coordinates && geocoderRef.current) {
//           const { results } = await geocoderRef.current.geocode({ location: coordinates });
//           if (results.length > 0) {
//             const address = getLocationFromGeocoderResponse(results[0]);
//             setLocation(address);
//           }
//         }
//       },
//       1000,
//       { maxWait: 6000 }
//     ),
//     [geocoderRef, setLocation]
//   );

//   const markerDragEndListener = useCallback(
//     (e) => {
//       if (mapRef.current) {
//         mapRef.current.panTo(e.latLng);
//         const coordinates = getLatLongFromLocation(e.latLng);
//         setLocationFromCoordinates(coordinates);
//       }
//     },
//     [setLocationFromCoordinates]
//   );

//   const setMarkerPosition = useCallback(
//     (latLng) => {
//       if (markerRef.current) {
//         const coordinates = getLatLongFromLocation(latLng);
//         markerRef.current.setPosition(latLng);
//         setLocationFromCoordinates(coordinates);
//       }
//     },
//     [setLocationFromCoordinates]
//   );

//   useEffect(() => {
//     if (mapDivRef.current) {
//       mapRef.current = new window.google.maps.Map(mapDivRef.current, {
//         center: initialCoordinates.current,
//         zoom: 15,
//         streetViewControl: false,
//       });
//       geocoderRef.current = new window.google.maps.Geocoder();

//       markerRef.current = new window.google.maps.Marker({
//         position: initialCoordinates.current,
//         map: mapRef.current,
//         draggable: true,
//         title: 'Event Location',
//       });

//       autoCompleteServiceRef.current = new window.google.maps.places.AutocompleteService();
//       placesDetailsRef.current = new window.google.maps.places.PlacesService(document.getElementById('places-service'));
//       sessionToken.current = new google.maps.places.AutocompleteSessionToken();

//       markerRef.current.addListener('dragend', markerDragEndListener, { passive: true });
//       mapRef.current.addListener('click', (e) => setMarkerPosition(e.latLng));
//       mapRef.current.addListener(
//         'center_changed',
//         debounce(() => setMarkerPosition(mapRef.current.getCenter()), 30, { maxWait: 30 })
//       );
//       setLocationFromCoordinates(initialCoordinates.current);
//     }
//   }, [init, markerDragEndListener, setLocationFromCoordinates, setMarkerPosition]);

//   return (
//     <Wrapper
//       callback={(status) => {
//         if (status === Status.SUCCESS) {
//           setTimeout(() => setInit(true), 300);
//         }
//       }}
//       libraries={['places']}
//       apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
//       region={'IN'}
//     >
//       <Autocomplete
//         autoComplete
//         getOptionLabel={(option) => option.description}
//         onInputChange={(event, value, reason) => {
//           if (reason !== 'reset') {
//             fetchPredictions(value);
//           }
//         }}
//         options={options ?? []}
//         renderInput={(params) => <TextField {...params} label='Add a location' variant='outlined' fullWidth />}
//         onChange={(event, value) => setLocationFromPlaceId(value?.place_id)}
//         renderOption={(option) => (
//           <Box display='flex' alignItems='center'>
//             <LocationOnIcon color='primary' style={{ marginRight: 16 }} />
//             <Typography variant='body2'>{option.description}</Typography>
//           </Box>
//         )}
//       />
//       <div id='google-maps-embed' ref={mapDivRef} style={{ minHeight: 300, height: '100%', width: '100%' }}></div>
//       <div id={'places-service'} />
//     </Wrapper>
//   );
// };

// GoogleMapsComponent.propTypes = {
//   location: PropTypes.shape({
//     formattedAddress: PropTypes.string.isRequired,
//     placeId: PropTypes.string.isRequired,
//     city: PropTypes.string.isRequired,
//     state: PropTypes.string.isRequired,
//     country: PropTypes.string.isRequired,
//     postalCode: PropTypes.string.isRequired,
//     coordinates: PropTypes.shape({
//       latitude: PropTypes.number.isRequired,
//       longitude: PropTypes.number.isRequired,
//     }),
//   }),
//   setLocation: PropTypes.func.isRequired,
// };

// export default GoogleMapsComponent;

import { Status, Wrapper } from '@googlemaps/react-wrapper';
import { Box, TextField, Typography } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { debounce } from 'lodash-es';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { getLatLongFromLocation, getLocationFromGeocoderResponse } from '../../utils/location-utils';

const GoogleMapsComponent = ({ location, setLocation, manualAddress, setManualAddress }) => {
  const autoCompleteServiceRef = useRef();
  const placesDetailsRef = useRef();
  const mapDivRef = useRef();
  const mapRef = useRef();
  const markerRef = useRef();
  const geocoderRef = useRef();
  const initialCoordinates = useRef(
    location
      ? { lat: location.coordinates.latitude, lng: location.coordinates.longitude }
      : { lat: 12.9472235, lng: 77.5844803 }
  );
  const sessionToken = useRef();

  const [init, setInit] = useState(false);
  const [options, setOptions] = useState([]);

  const fetchPredictions = (input) => {
    if (input) {
      autoCompleteServiceRef.current.getPlacePredictions({ input, sessionToken: sessionToken.current }, (results) => {
        setOptions(results || []);
      });
    } else {
      setOptions([]);
    }
  };

  const setLocationFromPlaceId = (placeId) => {
    if (placeId && placesDetailsRef.current) {
      placesDetailsRef.current.getDetails(
        {
          placeId,
          sessionToken: sessionToken.current,
        },
        (res) => {
          const address = getLocationFromGeocoderResponse(res);
          setMarkerPosition(res.geometry.location);
          mapRef.current.panTo(res.geometry.location);
          mapRef.current.setZoom(20);
          sessionToken.current = new google.maps.places.AutocompleteSessionToken();
          setLocation(address);
          setManualAddress('');
        }
      );
    }
  };
  const setLocationFromCoordinates = useCallback(
    debounce(
      async (coordinates) => {
        if (coordinates && geocoderRef.current) {
          const { results } = await geocoderRef.current.geocode({ location: coordinates });
          if (results.length > 0) {
            const address = getLocationFromGeocoderResponse(results[0]);
            setLocation(address);
          }
        }
      },
      1000,
      { maxWait: 6000 }
    ),
    [geocoderRef, setLocation]
  );

  const markerDragEndListener = useCallback(
    (e) => {
      if (mapRef.current) {
        mapRef.current.panTo(e.latLng);
        const coordinates = getLatLongFromLocation(e.latLng);
        setLocationFromCoordinates(coordinates);
      }
    },
    [setLocationFromCoordinates]
  );

  const setMarkerPosition = useCallback(
    (latLng) => {
      if (markerRef.current) {
        const coordinates = getLatLongFromLocation(latLng);
        markerRef.current.setPosition(latLng);
        setLocationFromCoordinates(coordinates);
      }
    },
    [setLocationFromCoordinates]
  );

  useEffect(() => {
    if (mapDivRef.current) {
      mapRef.current = new window.google.maps.Map(mapDivRef.current, {
        center: initialCoordinates.current,
        zoom: 15,
        streetViewControl: false,
      });
      geocoderRef.current = new window.google.maps.Geocoder();

      markerRef.current = new window.google.maps.Marker({
        position: initialCoordinates.current,
        map: mapRef.current,
        draggable: true,
        title: 'Event Location',
      });

      autoCompleteServiceRef.current = new window.google.maps.places.AutocompleteService();
      placesDetailsRef.current = new window.google.maps.places.PlacesService(document.getElementById('places-service'));
      sessionToken.current = new google.maps.places.AutocompleteSessionToken();

      markerRef.current.addListener('dragend', markerDragEndListener, { passive: true });
      mapRef.current.addListener('click', (e) => setMarkerPosition(e.latLng));
      mapRef.current.addListener(
        'center_changed',
        debounce(() => setMarkerPosition(mapRef.current.getCenter()), 30, { maxWait: 30 })
      );
      setLocationFromCoordinates(initialCoordinates.current);
    }
  }, [init, markerDragEndListener, setLocationFromCoordinates, setMarkerPosition]);

  return (
    <Wrapper
      callback={(status) => {
        if (status === Status.SUCCESS) {
          setTimeout(() => setInit(true), 300);
        }
      }}
      libraries={['places']}
      apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
      region={'IN'}
    >
      <TextField
        style={{ marginBottom: 20 }}
        value={manualAddress}
        onChange={(e) => setManualAddress(e.target.value)}
        label='Manual Address'
        variant='outlined'
        fullWidth
        margin='normal'
      />
      <Autocomplete
        autoComplete
        getOptionLabel={(option) => option.description}
        onInputChange={(event, value, reason) => {
          if (reason !== 'reset') {
            fetchPredictions(value);
          }
        }}
        options={options ?? []}
        renderInput={(params) => <TextField {...params} label='Add a location' variant='outlined' fullWidth />}
        onChange={(event, value) => setLocationFromPlaceId(value?.place_id)}
        renderOption={(option) => (
          <Box display='flex' alignItems='center'>
            <LocationOnIcon color='primary' style={{ marginRight: 16 }} />
            <Typography variant='body2'>{option.description}</Typography>
          </Box>
        )}
      />
      <div id='google-maps-embed' ref={mapDivRef} style={{ minHeight: 300, height: '100%', width: '100%' }}></div>
      <div id={'places-service'} />
    </Wrapper>
  );
};

GoogleMapsComponent.propTypes = {
  location: PropTypes.shape({
    formattedAddress: PropTypes.string.isRequired,
    placeId: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    postalCode: PropTypes.string.isRequired,
    coordinates: PropTypes.shape({
      latitude: PropTypes.number.isRequired,
      longitude: PropTypes.number.isRequired,
    }),
  }),
  setLocation: PropTypes.func.isRequired,
  manualAddress: PropTypes.string.isRequired,
  setManualAddress: PropTypes.func.isRequired,
};

export default GoogleMapsComponent;
