import { makeStyles, Typography } from '@material-ui/core';
import BrokenTicketPreservedSvg from '../assets/svgs/broken-ticket/broken-ticket-preserved.svg';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'grid',
    minHeight: '80vh',
    placeItems: 'center',
  },
  ticket: {
    background: `url(${BrokenTicketPreservedSvg}) no-repeat center`,
    backgroundSize: 'contain',
    display: 'grid',
    placeItems: 'center',
    overflow: 'visible',
    padding: '2rem 0',
    width: '100%',
    [theme.breakpoints.down(425)]: {
      height: '28vw',
      padding: 0,
      width: '90vw',
    },
  },
}));

const PageNotFound = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.ticket}>
          <div>
            <Typography variant={'h3'} align={'center'} gutterBottom style={{ fontWeight: 300 }}>
              ERROR 404
            </Typography>
            <Typography variant={'body1'} align={'center'} style={{ fontWeight: 300 }}>
              Page not found
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
