import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { ResponsiveImagesPropType } from '../../utils/type-utils';

const ResponsiveImage = ({ className, images, minHeight, ...props }) => {
  const sources = images.sources.sort((a, b) => (a.minWidth > b.minWidth ? -1 : 1)); // Descending order
  const type = Boolean(sources[0].jpeg) ? 'jpeg' : 'png';
  const styles = { minWidth: '100%' };
  if (minHeight) styles.minHeight = minHeight;
  if (type !== 'png') styles.backgroundColor = 'whitesmoke';

  return (
    <picture {...props}>
      {sources.map((source, index) => (
        <Fragment key={index}>
          <source media={`(min-width: ${source.minWidth}px)`} srcSet={source.webp} type={`image/webp`} />
          <source media={`(min-width: ${source.minWidth}px)`} srcSet={source[type]} type={`image/${type}`} />
        </Fragment>
      ))}
      <img
        alt={images.metadata.alt}
        className={className}
        loading={'lazy'}
        decoding={'sync'}
        sizes={sources.map((source) => `(min-width: ${source.minWidth}px) ${source.size}px`).join(', ')}
        srcSet={sources.map((source) => `${source[type]} ${source.size}w`).join(', ')}
        style={styles}
        src={sources[0][type]}
        width={'100%'}
      />
    </picture>
  );
};

ResponsiveImage.propTypes = {
  className: PropTypes.string,
  images: ResponsiveImagesPropType,
  minHeight: PropTypes.number.isRequired,
};

export default ResponsiveImage;
