import PropTypes from 'prop-types';

export const ChildrenPropType = PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired;

export const ResponsiveImageSourcePropType = PropTypes.shape({
  minWidth: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  webp: PropTypes.string.isRequired,
  jpeg: PropTypes.string,
  png: PropTypes.string,
});

export const ResponsiveImagesPropType = PropTypes.shape({
  metadata: PropTypes.shape({
    alt: PropTypes.string.isRequired,
  }).isRequired,
  sources: PropTypes.arrayOf(ResponsiveImageSourcePropType).isRequired,
}).isRequired;

export const TimestampPropType = PropTypes.oneOfType([
  PropTypes.shape({ seconds: PropTypes.number.isRequired, toDate: PropTypes.func.isRequired }),
  PropTypes.shape({ _seconds: PropTypes.number.isRequired }),
]).isRequired;
