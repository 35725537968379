import { call, put, select, take, takeLatest } from 'redux-saga/effects';
import { forgotPassword, signInWithEmailAndPassword, signOut } from '../../services/auth-service';
import { fetchUserProfile, fetchUsers } from '../../services/users-service';
import { getAuthErrorMessage } from '../../utils/auth-utils';
import { setNotification } from '../notifications/ducks';
import {
  fetchUsersSucceeded,
  latestUsersSelector,
  setUserProfile,
  subscribeUserProfile,
  UserActionTypes,
  userProfileSelector,
} from './ducks';
import { subscribeUpcomingEvents } from '../events/ducks';
import { eventChannel } from 'redux-saga';

export function* usersSaga() {
  yield takeLatest(UserActionTypes.SIGN_OUT_USER, signOutSaga);
  yield takeLatest(UserActionTypes.LOGIN_USER, loginUserSaga);
  yield takeLatest(UserActionTypes.FORGOT_PASSWORD_USER, forgotPasswordSaga);

  yield takeLatest(UserActionTypes.SET_CLAIMS, setClaimsSaga);
  yield takeLatest(UserActionTypes.SUBSCRIBE_USER_PROFILE, fetchProfileSaga);

  yield takeLatest(UserActionTypes.FETCH_USERS, fetchUsersSaga);
}

function* loginUserSaga(action) {
  const { email, password } = action.payload;
  try {
    yield call(signInWithEmailAndPassword, email, password);
  } catch (e) {
    console.error(e);
    yield put(setNotification(getAuthErrorMessage(e.code)));
  }
}

function* forgotPasswordSaga(action) {
  try {
    yield call(forgotPassword, action.payload.email);
  } catch (e) {
    console.error(e);
    yield put(setNotification(getAuthErrorMessage(e.code)));
  }
}

function* signOutSaga() {
  try {
    yield call(signOut);
    yield put({ type: 'CLEAR_STATE' });
  } catch (e) {
    console.error(e);
    yield put(setNotification(getAuthErrorMessage(e.code)));
  }
}

const fetchProfileListener = (id) => eventChannel((emit) => fetchUserProfile(id, emit));

function* fetchProfileSaga(action) {
  let listener;
  try {
    listener = fetchProfileListener(action.payload.id);
  } catch (e) {
    console.error(e);
    return;
  }

  try {
    while (true) {
      const { profile } = yield take(listener);
      yield put(setUserProfile(profile));
    }
  } catch (e) {
    console.error(e);
  }
}

function* setClaimsSaga(action) {
  const { claims } = action.payload;

  const profile = yield select(userProfileSelector);

  if (claims.organizer && (!profile || profile.id !== claims.organizer)) {
    yield put(subscribeUserProfile(claims.organizer));
    yield put(subscribeUpcomingEvents(claims.organizer));
  }
}

function* fetchUsersSaga(action) {
  const { append, limit } = action.payload;

  let latest = append ? yield select(latestUsersSelector) : undefined;
  let users = [];

  try {
    const snap = yield call(fetchUsers, latest, limit);
    if (snap.size !== 0) {
      users = snap.docs.map((doc) => doc.data());
      latest = snap.docs.slice(-1).pop();
    }
    yield put(fetchUsersSucceeded(append, snap.size === limit, latest, users));
  } catch (e) {
    console.error(e);
    yield put(setNotification(e.message));
  }
}
