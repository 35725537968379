import { IconButton, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { Refresh } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Export from '../components/common/Export';
import SEO from '../components/common/SEO';
import withAdminAuthenticatedRoute from '../components/hoc/withAdminAuthenticatedRoute';
import Layout from '../components/navigation/Layout';
import UserColumns from '../constants/grids/user_columns';
import { fetchUsers, usersDataSelector, usersLoadingSelector, usersSelector } from '../redux/users/ducks';
import { fetchAllUsers } from '../services/users-service';

const UsersTablePage = () => {
  const dispatch = useDispatch();

  const data = useSelector(usersDataSelector);
  const loading = useSelector(usersLoadingSelector);
  const { canFetch } = useSelector(usersSelector);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const rowsCount = data.length + (canFetch ? rowsPerPage : 0);

  const setState = (refresh = false) => {
    if (refresh) {
      dispatch(fetchUsers(false, rowsPerPage));
      setPage(0);
      return;
    }
    if (canFetch) {
      const limit = rowsPerPage * (page + 1) - data.length;
      limit > 0 && dispatch(fetchUsers(true, limit));
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(setState, [dispatch, page, canFetch, rowsPerPage]);

  return (
    <Layout>
      <SEO title={'Users'} />
      <TableToolbar setState={setState} />
      <DataGrid
        autoHeight
        style={{ width: '100%' }}
        columns={UserColumns}
        disableColumnMenu
        disableSelectionOnClick
        hideFooterSelectedRowCount
        loading={loading}
        page={page}
        onPageChange={(page) => setPage(page)}
        onPageSizeChange={(pageSize) => setRowsPerPage(pageSize)}
        pageSize={rowsPerPage}
        rows={data}
        rowCount={rowsCount}
        rowsPerPageOptions={[10, 25, 50, 75, 100]}
        scrollbarSize={70}
      />
    </Layout>
  );
};

const TableToolbar = ({ setState }) => {
  return (
    <Toolbar style={{ marginBottom: 8, padding: 0 }}>
      <Tooltip title={'Refresh'}>
        <IconButton aria-label={'refresh'} onClick={() => setState(true)}>
          <Refresh />
        </IconButton>
      </Tooltip>

      <Typography style={{ flex: '1 1 100%' }} variant={'h3'}>
        Users
      </Typography>

      <Tooltip title={'Export to Excel'}>
        <span>
          <Export filename={'users'} fetchData={fetchAllUsers} />
        </span>
      </Tooltip>
    </Toolbar>
  );
};

TableToolbar.propTypes = {
  setState: PropTypes.func.isRequired,
};

export default withAdminAuthenticatedRoute(UsersTablePage);
