import { call, put, select, takeLatest } from 'redux-saga/effects';
import { fetchOrganizers } from '../../services/orgaizers-service';
import { setNotification } from '../notifications/ducks';
import { fetchOrganizersSucceeded, latestOrganizerSelector, OrganizerActionTypes } from './ducks';

export function* sellersSaga() {
  yield takeLatest(OrganizerActionTypes.FETCH_ORGANIZERS, fetchOrganizersSaga);
}

function* fetchOrganizersSaga(action) {
  const { append, limit } = action.payload;

  let latest = append ? yield select(latestOrganizerSelector) : undefined;
  let organizers = [];

  try {
    const snap = yield call(fetchOrganizers, latest, limit);
    if (snap.size !== 0) {
      organizers = snap.docs.map((doc) => doc.data());
      latest = snap.docs.slice(-1).pop();
    }
    yield put(fetchOrganizersSucceeded(append, snap.size === limit, latest, organizers));
  } catch (e) {
    console.error(e);
    yield put(setNotification(e.message));
  }
}
