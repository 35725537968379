const INITIAL_STATE = {
  notifications: [],
};

export const NotificationActionTypes = {
  SET_NOTIFICATION: 'SET_NOTIFICATION',
  NOTIFICATION_TIMEOUT: 'NOTIFICATION_TIMEOUT',
};

export const setNotification = (message, type) => ({
  type: NotificationActionTypes.SET_NOTIFICATION,
  payload: { message, type },
});
export const notificationTimeout = () => ({ type: NotificationActionTypes.NOTIFICATION_TIMEOUT });

export const notificationsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NotificationActionTypes.SET_NOTIFICATION: {
      let { message, type } = action.payload;
      type = type ?? 'error';
      const notifications = [...state.notifications, { type, message }];
      return { ...state, notifications };
    }

    case NotificationActionTypes.NOTIFICATION_TIMEOUT: {
      const notifications = [...state.notifications].slice(1);
      return { ...state, notifications };
    }

    default:
      return state;
  }
};

export const notificationsSelector = (state) => state.notifications.notifications;
