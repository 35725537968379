import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Typography } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { ChildrenPropType } from '../../utils/type-utils';

const useStyles = makeStyles((theme) => ({
  'dropZone': {
    minHeight: 50,
    position: 'relative',
    width: '100%',
  },
  'disableEvents': {
    '& *': {
      pointerEvents: 'none !important',
    },
  },
  'overlay': {
    'alignItems': 'center',
    'backgroundColor': theme.palette.primary.main,
    'display': 'none',
    'flexDirection': 'column',
    'height': '100%',
    'justifyContent': 'center',
    'left': 0,
    'position': 'absolute',
    'top': 0,
    'transition': `opacity 0.5s`,
    'width': '100%',
    '& *': {
      pointerEvents: 'none !important',
    },
    '& svg': {
      animation: '$motion 1s infinite  ease-in-out alternate',
      fill: '#FFF',
      fontSize: 64,
    },
    '& h3': {
      color: '#FFF',
      fontWeight: 400,
      marginTop: 8,
    },
  },
  'enableOverlay': {
    display: 'flex',
    opacity: 0.5,
  },
  '@keyframes motion': {
    from: {
      transform: 'translateY(0px)',
    },
    to: {
      transform: 'translateY(-16px)',
    },
  },
}));

const WithDropZone = ({ children, uploadHandler }) => {
  const classes = useStyles();

  const [dragging, setDragging] = useState(false);

  const dragOver = (e) => e.preventDefault();

  const dragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const dragLeave = (e) => {
    e.preventDefault();
    if (e.target.id === `file-dropzone`) setDragging(false);
  };

  const validateFile = (file) => {
    const validTypes = ['image/jpeg', 'image/png'];
    return validTypes.indexOf(file.type) !== -1;
  };

  const handleFile = (file) => {
    if (validateFile(file)) {
      uploadHandler(file);
    }
  };
  const fileDrop = (e) => {
    e.preventDefault();
    if (dragging) setDragging(false);
    const files = e.dataTransfer.files;
    if (files.length !== 0) {
      handleFile(files[0]);
    }
  };

  return (
    <div
      id={`file-dropzone`}
      className={clsx(classes.dropZone, { [classes.disableEvents]: dragging })}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      onDragOver={dragOver}
      onDrop={fileDrop}
    >
      {children}
      <div className={clsx(classes.overlay, { [classes.enableOverlay]: dragging })}>
        <CloudUpload />
        <Typography align={'center'} color={'textPrimary'} style={{ padding: '0 8px' }} variant={'body1'}>
          Drop files anywhere to upload
        </Typography>
      </div>
    </div>
  );
};

WithDropZone.propTypes = {
  children: ChildrenPropType,
  uploadHandler: PropTypes.func.isRequired,
};

export default WithDropZone;
