import { Button, IconButton, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { Delete, Refresh } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Export from '../components/common/Export';
import SEO from '../components/common/SEO';
import Layout from '../components/navigation/Layout';
import { fetchOrganizers, organizersSelector } from '../redux/organizers/ducks';
import { disableOrDeleteOrganizer, enableOrganizer, fetchAllOrganizers } from '../services/orgaizers-service';
import OrganizerColumns from '../constants/grids/organizer_columns';
import withAdminAuthenticatedRoute from '../components/hoc/withAdminAuthenticatedRoute';

const OrganizersTablePage = () => {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState([]);

  const { canFetch, data, loading } = useSelector(organizersSelector);

  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);

  const rowsCount = data.length + (canFetch ? rowsPerPage : 0);

  const setState = (refresh = false) => {
    if (!refresh && canFetch) {
      const limit = rowsPerPage * page - data.length;
      limit > 0 && dispatch(fetchOrganizers(true, limit));
    } else {
      dispatch(fetchOrganizers(false, rowsPerPage));
      setPage(1);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(setState, [dispatch, page, canFetch, rowsPerPage]);

  return (
    <Layout>
      <SEO title={'Organizers'} />
      <TableToolbar setState={setState} selected={selected} setSelected={setSelected} />
      <DataGrid
        style={{ height: 800, width: '100%' }}
        // autoHeight
        columns={[
          ...OrganizerColumns,
          {
            field: 'disabled',
            headerName: 'Disabled',
            renderCell: function TableButton(params) {
              if (params.row.disabled) {
                return (
                  <Button
                    color={'primary'}
                    size={'small'}
                    onClick={async () => {
                      await enableOrganizer(params.row.id);
                      setState(true);
                    }}
                  >
                    Enable
                  </Button>
                );
              } else {
                return 'No';
              }
            },
            sortable: false,
            width: 110,
          },
        ]}
        checkboxSelection
        disableColumnMenu
        // hideFooterPagination
        disableSelectionOnClick
        hideFooterSelectedRowCount
        loading={loading}
        onPageChange={(page) => setPage(page + 1)}
        onPageSizeChange={(pageSize) => setRowsPerPage(pageSize)}
        onSelectionModelChange={(selected) => setSelected(selected)}
        pageSize={rowsPerPage}
        rows={data}
        rowCount={rowsCount}
        rowsPerPageOptions={[10, 25, 50, 75, 100]}
        scrollbarSize={70}
        selectionModel={selected}
      />
    </Layout>
  );
};

const TableToolbar = ({ selected, setSelected, setState }) => {
  return (
    <Toolbar style={{ marginBottom: 8, padding: 0 }}>
      <Tooltip title={'Refresh'}>
        <IconButton aria-label={'refresh'} onClick={() => setState(true)}>
          <Refresh />
        </IconButton>
      </Tooltip>

      <Typography style={{ flex: '1 1 100%' }} variant={'h3'}>
        Organizers
      </Typography>

      <Tooltip title={'Disable/Delete'}>
        <span>
          <IconButton
            aria-label={'delete'}
            disabled={selected.length !== 1}
            onClick={async () => {
              await disableOrDeleteOrganizer(selected[0]);
              setState(true);
              setSelected([]);
            }}
          >
            <Delete />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={'Export to Excel'}>
        <span>
          <Export filename={'organizers'} fetchData={fetchAllOrganizers} />
        </span>
      </Tooltip>
    </Toolbar>
  );
};

TableToolbar.propTypes = {
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired,
  setState: PropTypes.func.isRequired,
};

export default withAdminAuthenticatedRoute(OrganizersTablePage);
