import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  Button,
  Chip,
  Dialog,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Add, Close } from '@material-ui/icons';
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import clsx from 'clsx';
import { FastField, FieldArray, Form, Formik, getIn } from 'formik';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LoadingIndicator from '../components/common/LoadingIndicator';
import SEO from '../components/common/SEO';
import WithDropZone from '../components/common/WithDropZone';
import withOrganizerAuthenticatedRoute from '../components/hoc/withOrganizerAuthenticatedRoute';
import GoogleMapsComponent from '../components/maps/GoogleMapsComponent';
import Layout from '../components/navigation/Layout';
import Categories, { CategoriesJson } from '../constants/categories';
import Languages, { LanguagesJson } from '../constants/languages';
import Routes from '../constants/routes';
import { upcomingEventByIdSelector } from '../redux/events/ducks';
import { setNotification } from '../redux/notifications/ducks';
import { userProfileSelector } from '../redux/users/ducks';
import { putEvent } from '../services/events-service';
import { convertToDate } from '../utils/date-time-utils';
import { getValidationSchema } from '../utils/formik-utils';
import { getImageUrlFromImageSources } from '../utils/image-utils';
import PageNotFound from './404';

const useStyles = makeStyles((theme) => ({
  center: {
    display: 'grid',
    placeItems: 'center',
  },
  image: {
    margin: '0 auto',
    objectFit: 'cover',
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: '0.9rem',
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  tallImage: {
    borderRadius: 16,
    height: 350,
    width: 250,
  },
  squareImage: {
    borderRadius: 16,
    height: 250,
    width: 250,
  },
  carouselImage: {
    borderRadius: 24,
    height: 150,
    width: 300,
  },
  emptyImage: {
    backgroundColor: 'whitesmoke',
    padding: 16,
  },
  carouselImageUpload: {
    backgroundColor: 'whitesmoke',
    borderRadius: '20px',
    display: 'grid',
    height: 250,
    width: 250,
    margin: '1rem auto',
    placeItems: 'center',
  },
  closeIcon: {
    'backgroundColor': 'white',
    'border': '2px solid black',
    'opacity': 0.9,
    'position': 'absolute',
    'fontWeight': 'bold',
    'top': 0,
    'right': -10,
    '&:hover': {
      backgroundColor: 'white',
      opacity: 1,
    },
  },
  errorText: {
    marginLeft: 16,
    marginTop: 8,
  },
}));

const emptyTicket = {
  id: '',
  name: '',
  datetime: new Date(),
  description: '',
  type: '',
  totalTickets: '',
  price: '',
};

const initialValues = (event) => ({
  id: event?.id ?? '',
  about: event?.about ?? '',
  artist: {
    about: event?.artist?.about ?? '',
    name: event?.artist?.name ?? '',
  },
  categories: event?.categories ?? [],
  languages: event?.languages ?? [],
  information: event?.information ?? '',
  name: event?.name ?? '',
  tickets: event?.tickets?.map((ticket) => ({
    datetime: convertToDate(ticket.datetime),
    description: ticket.description,
    id: ticket.id,
    name: ticket.name,
    price: `${ticket.price}`,
    totalTickets: `${ticket.totalTickets}`,
    type: ticket.type,
  })) ?? [emptyTicket],
});

const EventFormPage = ({
  match: {
    params: { eventId },
  },
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const isEdit = eventId !== 'new';

  const profile = useSelector(userProfileSelector);

  const event = useSelector((state) => upcomingEventByIdSelector(state, eventId));

  const [location, setLocation] = useState(event?.location);
  const [manualAddress, setManualAddress] = useState('');
  const [images, setImages] = useState({ 0: { file: null, url: null } });
  const [squareImage, setSquareImage] = useState(null);
  const [tallImage, setTallImage] = useState(null);
  const [artistImage, setArtistImage] = useState(null);

  const tallImageLocalUrl = getImageUrlFromImageSources(event?.featuredImage);
  const squareImageLocalUrl = getImageUrlFromImageSources(event?.squareImage);
  const artistImageUrl = getImageUrlFromImageSources(event?.artist?.image);

  useEffect(() => {
    const imagesLength = Object.keys(event?.images ?? {}).length;
    if (imagesLength === 0) {
      return;
    }

    const temp = {};
    for (let i = 0; i < imagesLength; i++) {
      temp[`${i}`] = { file: null, url: getImageUrlFromImageSources(event.images[`${i}`]) };
    }
    temp[imagesLength] = { file: null, url: null };
    setImages(temp);
  }, [event?.images]);

  const handleImageUpload = (file, index) => {
    const temp = { ...images };
    temp[index.toString()] = { file, url: null };
    if (index === Object.keys(images).length - 1) {
      temp[(index + 1).toString()] = { file: null, url: null };
    }
    setImages(temp);
  };

  const handleImageDelete = (index) => {
    const totalImages = Object.keys(images).length;
    const temp = { ...images };
    delete temp[index.toString()];

    for (let i = index; i < totalImages - 1; i++) {
      temp[i.toString()] = { ...temp[(i + 1).toString()] };
    }
    delete temp[(totalImages - 1).toString()];
    setImages(temp);
  };

  const handleSubmit = async (values, helpers) => {
    if (!tallImage && !tallImageLocalUrl) {
      dispatch(setNotification('Event must have a Featured Banner'));
      return;
    } else if (!squareImage && !squareImageLocalUrl) {
      dispatch(setNotification('Event must have a Square Banner'));
      return;
    } else if (!artistImage && !artistImageUrl) {
      dispatch(setNotification('Artist must have an Avatar'));
      return;
    } else if (Object.keys(images).length <= 1) {
      dispatch(setNotification('Event must have Banner Images'));
      return;
    } else if (!location.postalCode) {
      dispatch(setNotification('The selected location does not have a postal code. Please check the event location.'));
      return;
    }

    const imagesArray = [];
    for (let i = 0; i < Object.keys(images).length - 1; i++) {
      imagesArray.push(images[i]);
    }

    try {
      const organizer = {
        id: profile.id,
        companyName: profile.companyName,
        panNumber: profile.panNumber,
        verifiers: [],
      };
      if (isEdit && artistImageUrl) {
        values.artist = { ...values.artist, image: event?.artist?.image };
      }
      await putEvent(
        isEdit ? { ...values, location, manualAddress } : { ...values, location, manualAddress, organizer },
        { artistImage, imagesArray, squareImage, tallImage },
        Object.keys(event?.images ?? {}).length
      );
      history.replace(Routes.UPCOMING_EVENTS);
    } catch (e) {
      console.error(e);
      dispatch(setNotification(e.message));
    } finally {
      helpers.setSubmitting(false);
    }
  };

  if (isEdit && !event) {
    return <PageNotFound />;
  }

  return (
    <Layout>
      <SEO title={isEdit ? 'Update Event' : 'Add Event'} />
      <Typography align={'center'} style={{ margin: '2rem 0' }} variant={'h2'}>
        {isEdit ? 'Update Event' : 'Add Event'}
      </Typography>
      <Typography color={'textSecondary'} paragraph variant={'h3'}>
        Upload Images
      </Typography>

      <Grid alignItems={'center'} container justifyContent={'center'} spacing={5}>
        <Grid item>
          <WithDropZone uploadHandler={(file) => setTallImage(file)}>
            {tallImage || tallImageLocalUrl ? (
              <div className={classes.center}>
                <Tooltip color={'primary'} title={'Drop image here to change featured image'}>
                  <img
                    alt={'Category'}
                    className={clsx(classes.tallImage, classes.image)}
                    src={!tallImage ? tallImageLocalUrl : URL.createObjectURL(tallImage)}
                  />
                </Tooltip>
              </div>
            ) : (
              <div className={clsx(classes.tallImage, classes.emptyImage, classes.image, classes.center)}>
                <Typography align={'center'} variant={'body1'} color={'secondary'}>
                  Drop Featured Image Here (350x250)
                </Typography>
              </div>
            )}
          </WithDropZone>
        </Grid>
        <Grid item>
          <WithDropZone uploadHandler={(file) => setSquareImage(file)}>
            {squareImage || squareImageLocalUrl ? (
              <div className={classes.center}>
                <Tooltip color={'primary'} title={'Drop image here to change square image'}>
                  <img
                    alt={'Tall Banner'}
                    className={clsx(classes.squareImage, classes.image)}
                    src={!squareImage ? squareImageLocalUrl : URL.createObjectURL(squareImage)}
                  />
                </Tooltip>
              </div>
            ) : (
              <div className={clsx(classes.squareImage, classes.emptyImage, classes.image, classes.center)}>
                <Typography align={'center'} variant={'body1'} color={'secondary'}>
                  Drop Featured Square Here (250x250)
                </Typography>
              </div>
            )}
          </WithDropZone>
        </Grid>
      </Grid>

      <Grid container justifyContent={'center'} style={{ marginBottom: 16 }} spacing={5}>
        {Object.values(images).map(({ file, url }, index) => (
          <Grid key={index} item>
            <WithDropZone uploadHandler={(file) => handleImageUpload(file, index)}>
              {file || url ? (
                <Box className={classes.center} style={{ position: 'relative' }}>
                  <Tooltip color={'primary'} title={'Drop image here to upload'}>
                    <img
                      alt={`Banner-${index}`}
                      className={clsx(classes.carouselImage, classes.image)}
                      src={!file ? url : URL.createObjectURL(file)}
                    />
                  </Tooltip>
                  <Tooltip color={'primary'} title={'Delete'}>
                    <IconButton
                      onClick={() => handleImageDelete(index)}
                      style={{ position: 'absolute', right: 0, top: 0 }}
                    >
                      <Close fontSize={'small'} />
                    </IconButton>
                  </Tooltip>
                </Box>
              ) : (
                <div className={clsx(classes.carouselImage, classes.emptyImage, classes.image, classes.center)}>
                  <Typography align={'center'} variant={'body1'} color={'secondary'}>
                    Drop Banner Images Here (150x300)
                  </Typography>
                </div>
              )}
            </WithDropZone>
          </Grid>
        ))}
      </Grid>

      <Formik
        initialValues={initialValues(event)}
        validationSchema={getValidationSchema(Object.keys(initialValues()), 'event')}
        onSubmit={handleSubmit}
        validateOnBlur
        validateOnChange
      >
        {({ errors, handleChange, isSubmitting, setFieldValue, touched, values }) => (
          <Form autoComplete={'off'} autoCapitalize={'words'} name={isEdit ? 'Update Event' : 'Add Event'}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FastField
                  component={TextField}
                  disabled={isSubmitting}
                  fullWidth
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  id={'name'}
                  label={'Event Name'}
                  name={'name'}
                  onChange={handleChange}
                  required
                  value={values.name}
                  variant={'outlined'}
                />
              </Grid>
              <Grid item xs={12}>
                <FastField
                  component={TextField}
                  disabled={isSubmitting}
                  fullWidth
                  error={touched.about && Boolean(errors.about)}
                  helperText={touched.about && errors.about}
                  id={'about'}
                  label={'About the Event'}
                  multiline
                  name={'about'}
                  onChange={handleChange}
                  required
                  rows={3}
                  value={values.about}
                  variant={'outlined'}
                />
              </Grid>
              <Grid item xs={12}>
                <FastField
                  component={TextField}
                  disabled={isSubmitting}
                  fullWidth
                  error={touched.information && Boolean(errors.information)}
                  helperText={touched.information && errors.information}
                  id={'information'}
                  label={'Event Information'}
                  multiline
                  name={'information'}
                  onChange={handleChange}
                  required
                  rows={3}
                  value={values.information}
                  variant={'outlined'}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography gutterBottom style={{ marginTop: 16 }} variant={'body2'}>
                  Categories
                </Typography>
                <Select
                  error={touched.categories && Boolean(errors.categories)}
                  disabled={isSubmitting}
                  fullWidth
                  input={<Input />}
                  multiple
                  onChange={(e) => setFieldValue('categories', e.target.value)}
                  required
                  value={values.categories}
                  renderValue={(selected) => (
                    <Box display={'flex'} flexWrap={'wrap'}>
                      {selected.map((value) => (
                        <Chip
                          color={'primary'}
                          key={value}
                          label={CategoriesJson[value]}
                          style={{ marginLeft: 8, marginTop: 8 }}
                        />
                      ))}
                    </Box>
                  )}
                >
                  {Categories.map(({ key, name }) => (
                    <MenuItem key={key} value={key}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography gutterBottom style={{ marginTop: 16 }} variant={'body2'}>
                  Languages
                </Typography>
                <Select
                  error={touched.languages && Boolean(errors.languages)}
                  disabled={isSubmitting}
                  fullWidth
                  input={<Input />}
                  multiple
                  onChange={(e) => setFieldValue('languages', e.target.value)}
                  required
                  value={values.languages}
                  renderValue={(selected) => (
                    <Box display={'flex'} flexWrap={'wrap'}>
                      {selected.map((value) => (
                        <Chip
                          color={'primary'}
                          key={value}
                          label={LanguagesJson[value]}
                          style={{ marginLeft: 8, marginTop: 8 }}
                        />
                      ))}
                    </Box>
                  )}
                >
                  {Languages.map(({ label, value }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              {/* TICKETS */}
              <Grid item xs={12}>
                <Typography color={'textSecondary'} paragraph style={{ marginTop: 16 }} variant={'h3'}>
                  Ticket Details
                </Typography>

                <FieldArray
                  name={'tickets'}
                  render={(arrayHelpers) => (
                    <>
                      {values.tickets.map((ticket, index) => (
                        <Box key={index} alignItems={'center'} display={'flex'}>
                          <IconButton
                            disabled={isSubmitting || values.tickets.length <= 1}
                            onClick={values.tickets.length <= 1 ? null : () => arrayHelpers.remove(index)}
                          >
                            <Close />
                          </IconButton>
                          <Grid container spacing={3} style={{ marginBottom: 16, flexGrow: 1 }}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <Grid item xs={12} sm={6}>
                                <KeyboardDatePicker
                                  disabled={isSubmitting}
                                  disablePast
                                  label={'Slot Date'}
                                  onChange={(date) => setFieldValue(`tickets.${index}.datetime`, date)}
                                  value={values.tickets[index].datetime}
                                  variant={'inline'}
                                />

                                <FormHelperText className={classes.errorText} error>
                                  {getIn(touched, `tickets.${index}.datetime`) &&
                                    getIn(errors, `tickets.${index}.datetime`)}
                                </FormHelperText>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <KeyboardTimePicker
                                  ampm={true}
                                  disabled={isSubmitting}
                                  label={'Slot Time'}
                                  onChange={(date) => {
                                    console.info(date instanceof Date);
                                    setFieldValue(`tickets.${index}.datetime`, date);
                                  }}
                                  // onChange={(time) => setFieldValue(`tickets.${index}.datetime`, time)}
                                  value={values.tickets[index].datetime}
                                  variant={'inline'}
                                />
                              </Grid>
                            </MuiPickersUtilsProvider>
                            <Grid item xs={12} sm={6}>
                              <FastField
                                component={TextField}
                                disabled={isSubmitting}
                                fullWidth
                                id={`tickets.${index}.name`}
                                label={'Ticket Name'}
                                name={`tickets.${index}.name`}
                                onChange={handleChange}
                                required
                                value={values.tickets[index].name}
                                variant={'outlined'}
                              />
                              <FormHelperText className={classes.errorText} error>
                                {getIn(touched, `tickets.${index}.name`) && getIn(errors, `tickets.${index}.name`)}
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FastField
                                component={TextField}
                                disabled={isSubmitting}
                                fullWidth
                                id={`tickets.${index}.type`}
                                label={'Ticket Type'}
                                name={`tickets.${index}.type`}
                                onChange={handleChange}
                                value={values.tickets[index].type}
                                variant={'outlined'}
                              />
                              <FormHelperText className={classes.errorText} error>
                                {getIn(touched, `tickets.${index}.type`) && getIn(errors, `tickets.${index}.type`)}
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FastField
                                component={TextField}
                                disabled={isSubmitting}
                                fullWidth
                                id={`tickets.${index}.totalTickets`}
                                label={'Total Tickets'}
                                name={`tickets.${index}.totalTickets`}
                                onChange={handleChange}
                                required
                                value={values.tickets[index].totalTickets}
                                variant={'outlined'}
                              />
                              <FormHelperText className={classes.errorText} error>
                                {getIn(touched, `tickets.${index}.totalTickets`) &&
                                  getIn(errors, `tickets.${index}.totalTickets`)}
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FastField
                                component={TextField}
                                disabled={isSubmitting}
                                fullWidth
                                id={`tickets.${index}.price`}
                                label={'Price'}
                                name={`tickets.${index}.price`}
                                onChange={handleChange}
                                required
                                value={values.tickets[index].price}
                                variant={'outlined'}
                              />
                              <FormHelperText className={classes.errorText} error>
                                {getIn(touched, `tickets.${index}.price`) && getIn(errors, `tickets.${index}.price`)}
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12}>
                              <FastField
                                multiline
                                component={TextField}
                                disabled={isSubmitting}
                                fullWidth
                                id={`tickets.${index}.description`}
                                label={'Description'}
                                name={`tickets.${index}.description`}
                                onChange={handleChange}
                                value={values.tickets[index].description}
                                variant={'outlined'}
                              />
                              <FormHelperText className={classes.errorText} error>
                                {getIn(touched, `tickets.${index}.description`) &&
                                  getIn(errors, `tickets.${index}.description`)}
                              </FormHelperText>
                            </Grid>
                          </Grid>
                        </Box>
                      ))}

                      <Button
                        color={'primary'}
                        disabled={isSubmitting}
                        variant={'contained'}
                        style={{ margin: '1rem 0' }}
                        startIcon={<Add />}
                        onClick={() => arrayHelpers.push(emptyTicket)}
                      >
                        Add Ticket
                      </Button>
                    </>
                  )}
                />
              </Grid>
              {/* ARTIST */}
              <Grid item xs={12}>
                <Typography color={'textSecondary'} paragraph style={{ marginTop: 16 }} variant={'h3'}>
                  Artist Details
                </Typography>
                <Box mb={4}>
                  <WithDropZone uploadHandler={(file) => setArtistImage(file)}>
                    {artistImage || artistImageUrl ? (
                      <div className={classes.center}>
                        <Tooltip color={'primary'} title={'Drop image here to change artist image'}>
                          <img
                            alt={'Artist'}
                            className={clsx(classes.squareImage, classes.image)}
                            src={!artistImage ? artistImageUrl : URL.createObjectURL(artistImage)}
                          />
                        </Tooltip>
                      </div>
                    ) : (
                      <div className={clsx(classes.squareImage, classes.emptyImage, classes.image, classes.center)}>
                        <Typography align={'center'} variant={'body1'} color={'secondary'}>
                          Drop Artist Image Here
                        </Typography>
                      </div>
                    )}
                  </WithDropZone>
                </Box>
                <FastField
                  component={TextField}
                  disabled={isSubmitting}
                  fullWidth
                  error={getIn(touched, `artist.name`) && getIn(errors, `artist.name`)}
                  helperText={getIn(touched, `artist.name`) && getIn(errors, `artist.name`)}
                  id={`artist.name`}
                  label={'Artist Name'}
                  margin={'normal'}
                  name={`artist.name`}
                  onChange={handleChange}
                  required
                  value={values.artist.name}
                  variant={'outlined'}
                />
                <FastField
                  component={TextField}
                  disabled={isSubmitting}
                  fullWidth
                  error={touched.artist?.about && Boolean(errors.artist?.about)}
                  helperText={touched.artist?.about && errors.artist?.about}
                  id={`artist.about`}
                  label={'About Artist'}
                  margin={'normal'}
                  multiline
                  name={`artist.about`}
                  onChange={handleChange}
                  required
                  rows={3}
                  value={values.artist.about}
                  variant={'outlined'}
                />
              </Grid>

              <Grid item xs={12} style={{ marginBottom: 200 }}>
                <Typography color={'textSecondary'} paragraph style={{ marginTop: 16 }} variant={'h3'}>
                  Location
                </Typography>
                <GoogleMapsComponent
                  style={{ marginTop: 20 }}
                  location={location}
                  setLocation={setLocation}
                  manualAddress={manualAddress}
                  setManualAddress={setManualAddress}
                />
                <Typography style={{ marginTop: 18 }}>{location?.formattedAddress}</Typography>
              </Grid>
            </Grid>

            <Button
              color={'primary'}
              disabled={isSubmitting}
              fullWidth
              style={{ display: 'block', margin: '2.5rem auto', marginTop: '5rem', maxWidth: 300 }}
              type={'submit'}
              variant={'contained'}
            >
              {isEdit ? 'Update Event' : 'Add Event'}
            </Button>

            <Dialog open={isSubmitting} onClose={() => {}} disableEscapeKeyDown maxWidth={'xs'}>
              <Box p={10}>
                <LoadingIndicator noMinHeight />
                <Typography color={'textPrimary'}>Uploading event . . .</Typography>
              </Box>
            </Dialog>
          </Form>
        )}
      </Formik>
    </Layout>
  );
};

EventFormPage.propTypes = {
  match: PropTypes.shape({ params: PropTypes.shape({ eventId: PropTypes.string.isRequired }) }).isRequired,
};

const FormTextField = ({ id, label, ...props }) => {
  return <TextField fullWidth id={id} name={id} label={label} required variant={'outlined'} {...props} />;
};

FormTextField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  props: PropTypes.object,
};

export default withOrganizerAuthenticatedRoute(EventFormPage);
