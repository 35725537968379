// GENERATED

import Logo400Png from 'assets/images/generated/logo/png/logo-400w.png';
import Logo400Webp from 'assets/images/generated/logo/webp/logo-400w.webp';

const LogoSources = {
  metadata: { alt: 'Logo' },
  sources: [{ minWidth: 0, size: 400, png: Logo400Png, webp: Logo400Webp }],
};

export default LogoSources;
