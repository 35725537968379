import React, { useState } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import BookingColumns from '../../constants/grids/bookings_columns';
import { Toolbar, Tooltip, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import Export from './Export';

const BookingsTable = ({ bookings, fetchData }) => {
  const [rowsPerPage, setRowsPerPage] = useState(50);

  return (
    <>
      <BookingsToolbar fetchData={fetchData} />
      <DataGrid
        autoHeight
        columns={BookingColumns}
        disableSelectionOnClick
        hideFooterSelectedRowCount
        onPageSizeChange={(params) => setRowsPerPage(params.pageSize)}
        rows={bookings}
        pageSize={rowsPerPage}
        rowsPerPageOptions={[10, 25, 50, 75, 100]}
        scrollbarSize={70}
      />
    </>
  );
};

BookingsTable.propTypes = {
  bookings: PropTypes.array.isRequired,
  fetchData: PropTypes.func.isRequired,
};

const BookingsToolbar = ({ fetchData }) => {
  return (
    <Toolbar style={{ marginBottom: 8, padding: 0, width: '100%' }}>
      <Typography style={{ flex: '1 1 100%' }} variant={'h3'}>
        Bookings
      </Typography>

      <Tooltip style={{ marginLeft: 'auto' }} title={'Export to Excel'}>
        <span>
          <Export filename={'bookings'} fetchData={fetchData} />
        </span>
      </Tooltip>
    </Toolbar>
  );
};

BookingsToolbar.propTypes = {
  fetchData: PropTypes.func.isRequired,
};

export default BookingsTable;
