const Routes = {
  LANDING: '/',
  404: '/404',

  UPCOMING_EVENTS: '/events/upcoming',
  PAST_EVENTS: '/events/past',
  eventForm: (id) => `/events/form/${id}`,
  EVENT: '/event',

  event: (id) => `/events/${id}`,
  eventPreview: (id) => `https://theticketing.in/event-preview/${id}`,

  // AUTH
  LOGIN: '/login',
  PROFILE: '/profile',

  PRIVACY_POLICY: '/privacy-policy',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  FAQ: '/faqs',
  CONTACT: '/contact',

  // Organizers
  ORGANIZERS: '/organizers',
  organizer: (id) => `/organizers/${id}`,

  // Users
  USERS: '/users',
  user: (id) => `/users/${id}`,
};

export default Routes;
