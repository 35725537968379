const INITIAL_STATE = {
  data: [],
  loading: false,
  latest: null,
  canFetch: true,
};

export const OrganizerActionTypes = {
  FETCH_ORGANIZERS: 'FETCH_SELLERS',
  FETCH_ORGANIZERS_SUCCEEDED: 'FETCH_SELLERS_SUCCEEDED',

  CLEAR_STATE: 'CLEAR_STATE',
};

export const fetchOrganizers = (append, limit) => ({
  type: OrganizerActionTypes.FETCH_ORGANIZERS,
  payload: { append, limit },
});
export const fetchOrganizersSucceeded = (append, canFetch, latest, organizers) => ({
  type: OrganizerActionTypes.FETCH_ORGANIZERS_SUCCEEDED,
  payload: { append, canFetch, latest, organizers },
});

export const organizersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OrganizerActionTypes.FETCH_ORGANIZERS:
      return { ...state, loading: true };

    case OrganizerActionTypes.FETCH_ORGANIZERS_SUCCEEDED: {
      const { append, canFetch, latest, organizers } = action.payload;
      return {
        ...state,
        canFetch,
        data: append ? [...state.data, ...organizers] : organizers,
        latest,
        loading: false,
      };
    }

    case OrganizerActionTypes.CLEAR_STATE:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export const organizersSelector = (state) => state.organizers;
export const latestOrganizerSelector = (state) => state.organizers.latest;
