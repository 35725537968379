import firebase from 'firebase/app';
import { chunk } from 'lodash-es';
import { formatDateTime } from '../utils/date-time-utils';
import { formatBookingsForExport } from '../utils/export-utils';

export const fetchUsers = async (latest, limit) => {
  let query = firebase.firestore().collection('users').orderBy('createdAt', 'desc');
  if (latest) {
    query = query.startAfter(latest);
  }
  return query.limit(limit).get();
};

export const fetchAllUsers = async () =>
  firebase
    .firestore()
    .collection('users')
    .get()
    .then((snap) =>
      snap.docs.map((doc) => {
        const data = doc.data();
        data.avatar = data.avatar ? data.avatar.sources[0].jpeg ?? data.avatar.sources[0].png : '';
        data.categories = data.categories.join(', ');
        data.createdAt = formatDateTime(data.createdAt);
        data.bookmarkedEvents = data.bookmarkedEvents.join(', ');
        data.recentlyViewedEvents = data.recentlyViewedEvents.join(', ');
        data.updatedAt = formatDateTime(data.updatedAt);
        return data;
      })
    );

export const fetchUserById = async (id) => {
  const db = firebase.firestore();

  const [user, bookings] = await Promise.all([
    db
      .collection('users')
      .doc(id)
      .get()
      .then((doc) => doc.data()),
    fetchUserBookings(id),
  ]);
  if (!user) throw new Error('User not found');

  const [bookmarkedEvents] = await Promise.all(
    user.bookmarkedEvents.length
      ? chunk(user.bookmarkedEvents, 10)
          .map((bookmarkedEventsArray) =>
            db
              .collection('events')
              .where('id', 'in', bookmarkedEventsArray)
              .get()
              .then((snap) => snap.docs.map((doc) => doc.data()))
          )
          .flat()
      : [Promise.resolve([])]
  );

  return { ...user, bookings, bookmarkedEvents };
};

export const fetchUserBookings = async (id, format = false) =>
  firebase
    .firestore()
    .collection('bookings')
    .where('user.id', '==', id)
    .orderBy('time', 'desc')
    .get()
    .then((snap) => {
      const bookings = snap.docs.map((doc) => doc.data());
      if (format) {
        return formatBookingsForExport(bookings);
      }
      return bookings;
    });

export const fetchUserProfile = (id, emit) =>
  firebase
    .firestore()
    .collection('organizers')
    .doc(id)
    .onSnapshot((doc) => emit({ profile: doc.data() }));
