export const getLatLongFromLocation = (location) => (location ? { lat: location.lat(), lng: location.lng() } : null);

// https://github.com/moroshko/autosuggest-highlight/blob/master/src/parse.js
// export const parse = (text, matches) => {
//   const result = [];
//
//   if (matches.length === 0) {
//     result.push({
//       text: text,
//       highlight: false,
//     });
//   } else {
//     if (matches[0][0] > 0) {
//       result.push({
//         text: text.slice(0, matches[0][0]),
//         highlight: false,
//       });
//     }
//   }
//
//   matches.forEach(function (match, i) {
//     const startIndex = match[0];
//     const endIndex = match[1];
//
//     result.push({
//       text: text.slice(startIndex, endIndex),
//       highlight: true,
//     });
//
//     if (i === matches.length - 1) {
//       if (endIndex < text.length) {
//         result.push({
//           text: text.slice(endIndex, text.length),
//           highlight: false,
//         });
//       }
//     } else if (endIndex < matches[i + 1][0]) {
//       result.push({
//         text: text.slice(endIndex, matches[i + 1][0]),
//         highlight: false,
//       });
//     }
//   });
//
//   return result;
// };

export const getLocationFromGeocoderResponse = (res) => {
  const address = {
    formattedAddress: res.formatted_address,
    placeId: res.place_id,
    coordinates: { latitude: res.geometry.location.lat(), longitude: res.geometry.location.lng() },
  };

  let cityField = 'administrative_area_level_2';
  res.address_components.some(({ types }) => {
    if (types.includes('locality')) {
      cityField = 'locality';
      return true;
    }
    return false;
  });

  // eslint-disable-next-line camelcase
  res.address_components.forEach(({ types, long_name }) => {
    if (types.includes(cityField)) {
      // eslint-disable-next-line camelcase
      address.city = long_name;
    } else if (types.includes('administrative_area_level_1')) {
      // eslint-disable-next-line camelcase
      address.state = long_name;
    } else if (types.includes('postal_code')) {
      // eslint-disable-next-line camelcase
      address.postalCode = long_name;
    } else if (types.includes('country')) {
      // eslint-disable-next-line camelcase
      address.country = long_name;
    }
  });

  return address;
};
