import LoadingPage from 'pages/loading_page';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Routes from '../../constants/routes';
import { userClaimsSelector, userSelector } from '../../redux/users/ducks';

const withUnauthenticatedRoute = (ChildComponent) => (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const claims = useSelector(userClaimsSelector);
  const user = useSelector(userSelector);

  useEffect(() => {
    if (!claims.loading && user) {
      if (claims.admin || claims.organizer) {
        history.replace(Routes.UPCOMING_EVENTS);
      }
    }
  }, [claims, dispatch, history, user]);

  if (claims.loading) {
    return <LoadingPage />;
  }

  return <ChildComponent {...props} />;
};

export default withUnauthenticatedRoute;
