import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Toolbar,
  useMediaQuery,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Business, ChevronLeft, ChevronRight, EventAvailable, EventBusy, People, Person } from '@material-ui/icons';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import LogoSources from '../../constants/image/sources/logo-sources';
import Routes from '../../constants/routes';
import { signOutUser, userSelector } from '../../redux/users/ducks';
import ResponsiveImage from '../common/ResponsiveImage';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  appBarShift: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  hide: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  drawer: {
    'whiteSpace': 'nowrap',
    'width': drawerWidth,
    '& div::-webkit-scrollbar': {
      width: 0,
    },
  },
  drawerOpen: {
    width: drawerWidth,
  },
  drawerClose: {
    [theme.breakpoints.up('sm')]: {
      overflowX: 'hidden',
      width: 57,
    },
  },
  enteringTransition: {
    [theme.breakpoints.up('sm')]: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  leavingTransition: {
    [theme.breakpoints.up('sm')]: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  drawerHeader: {
    ...theme.mixins.toolbar, // necessary for content to be below app bar
  },
  content: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: 57,
    },
  },
  contentShrink: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: 240,
    },
  },
}));

const adminDrawerItems = [
  { title: 'Upcoming Events', link: Routes.UPCOMING_EVENTS, icon: <EventAvailable /> },
  { title: 'Past Events', link: Routes.PAST_EVENTS, icon: <EventBusy /> },
  { title: 'Users', link: Routes.USERS, icon: <People /> },
  { title: 'Organizers', link: Routes.ORGANIZERS, icon: <Business /> },
  { title: 'Profile', link: Routes.PROFILE, icon: <Person /> },
];

const sellerDrawerItems = [
  { title: 'Upcoming Events', link: Routes.UPCOMING_EVENTS, icon: <EventAvailable /> },
  { title: 'Past Events', link: Routes.PAST_EVENTS, icon: <EventBusy /> },
  { title: 'Profile', link: Routes.PROFILE, icon: <Person /> },
];

const drawerItems = process.env.REACT_APP_BUILD_TYPE === 'ORGANIZER' ? sellerDrawerItems : adminDrawerItems;

const Layout = ({ children }) => {
  const classes = useStyles();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(localStorage.getItem('getSelectedItem') || '');
  const overrideSidebar = !isMobile && !open;

  const handleDrawerOpen = () => setOpen(true);

  const handleDrawerClose = () => setOpen(false);

  return (
    <>
      <AppBar
        color={'secondary'}
        className={clsx(classes.appBar, classes.enteringTransition, {
          // [classes.appBarShift]: open,
          // [classes.leavingTransition]: open,
          [classes.leavingTransition]: overrideSidebar || open,
        })}
        elevation={1}
        position={'fixed'}
      >
        <Header open={open} setOpen={setOpen} />
      </AppBar>
      <SwipeableDrawer
        anchor={'left'}
        elevation={1}
        open={open}
        onClose={handleDrawerClose}
        onOpen={handleDrawerOpen}
        className={clsx(classes.drawer, {
          // [classes.drawerOpen]: open,
          // [classes.enteringTransition]: open,
          // [classes.drawerClose]: !open,
          // [classes.leavingTransition]: !open,
          [classes.drawerOpen]: overrideSidebar || open,
          [classes.enteringTransition]: overrideSidebar || open,
          [classes.drawerClose]: !overrideSidebar && !open,
          [classes.leavingTransition]: !overrideSidebar && !open,
        })}
        classes={{
          paper: clsx({
            // [classes.drawerOpen]: open,
            // [classes.enteringTransition]: open,
            // [classes.drawerClose]: !open,
            // [classes.leavingTransition]: !open,
            [classes.drawerOpen]: overrideSidebar || open,
            [classes.enteringTransition]: overrideSidebar || open,
            [classes.drawerClose]: !overrideSidebar && !open,
            [classes.leavingTransition]: !overrideSidebar && !open,
          }),
        }}
        variant={isMobile ? 'temporary' : 'permanent'}
      >
        <Toolbar style={{ justifyContent: 'flex-end' }}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeft />
          </IconButton>
        </Toolbar>
        <Divider />
        <List>
          {drawerItems.map(({ icon, link, title }) => (
            <Fragment key={link}>
              <Link to={link}>
                <ListItem
                  button
                  selected={selectedItem === title}
                  onClick={() => localStorage.setItem('getSelectedItem', title)}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={title} primaryTypographyProps={{ color: 'textPrimary' }} />
                </ListItem>
              </Link>
              <Divider />
            </Fragment>
          ))}
        </List>
      </SwipeableDrawer>
      <main
        className={clsx({
          // [classes.content]: !open,
          // [classes.enteringTransition]: open,
          // [classes.contentShrink]: open,
          // [classes.leavingTransition]: !open,
          [classes.content]: !overrideSidebar && !open,
          [classes.enteringTransition]: overrideSidebar || open,
          [classes.contentShrink]: overrideSidebar || open,
          [classes.leavingTransition]: !overrideSidebar && !open,
        })}
        style={{ marginTop: 32 }}
      >
        <div className={classes.drawerHeader} />
        <Container>{children}</Container>
      </main>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

const headerStyles = makeStyles((theme) => ({
  icon: {
    opacity: 1,
    visibility: 'visible',
  },
  transition: {
    transition: theme.transitions.create(['visibility', 'opacity'], {
      delay: theme.transitions.duration.leavingScreen,
      duration: 0,
    }),
  },
  hide: {
    opacity: 0,
    visibility: 'hidden',
  },
}));

const Header = ({ open, setOpen }) => {
  const classes = headerStyles();
  const dispatch = useDispatch();

  const user = useSelector(userSelector);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Toolbar style={{ justifyContent: 'space-between', paddingLeft: 0 }}>
      {isMobile ? (
        <IconButton
          className={clsx(classes.icon, { [classes.hide]: open, [classes.transition]: !open })}
          onClick={() => setOpen(true)}
        >
          <ChevronRight style={{ fill: '#fff' }} />
        </IconButton>
      ) : (
        <div />
      )}

      <Box maxWidth={150}>
        <ResponsiveImage minHeight={20.25} images={LogoSources} />
      </Box>

      <Button
        style={{ borderColor: '#9e9e9e', color: '#9e9e9e', visibility: user ? 'visible' : 'hidden' }}
        onClick={() => dispatch(signOutUser())}
        variant={'outlined'}
      >
        Logout
      </Button>
    </Toolbar>
  );
};

Header.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default Layout;
