import { format, fromUnixTime } from 'date-fns';

export const convertToDate = (timestamp) => {
  if (!timestamp) return new Date();
  return timestamp.toDate ? timestamp.toDate() : fromUnixTime(timestamp?.seconds ?? timestamp._seconds);
};

export const formatDate = (timestamp) => {
  if (!timestamp) return new Date().toLocaleDateString();
  const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp?.seconds ?? timestamp._seconds);
  return format(date, 'PP');
};

export const formatDateTime = (timestamp) => {
  if (!timestamp) return new Date().toLocaleDateString();
  const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp?.seconds ?? timestamp._seconds);
  return format(date, 'Pp');
};
