import { Button, Container, Paper, TextField, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ResponsiveImage from 'components/common/ResponsiveImage';
import withUnauthenticatedRoute from 'components/hoc/withUnauthenticatedRoute';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import SEO from '../components/common/SEO';
import LogoSources from '../constants/image/sources/logo-sources';
import { setNotification } from '../redux/notifications/ducks';
import { loginUser } from '../redux/users/ducks';
import { forgotPassword } from '../services/auth-service';
import { getAuthErrorMessage } from '../utils/auth-utils';

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 12,
    padding: '3rem',
  },
  logo: {
    width: 175,
    minWidth: `175px !important`,
  },
  field: {
    width: 235,
    marginTop: 24,
  },
  button: {
    marginTop: 40,
    width: 235,
  },
  forgotPasswordButton: {
    'display': 'block',
    'fontSize': 14,
    'fontWeight': theme.typography.fontWeightRegular,
    'marginTop': 24,
    'textTransform': 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const LoginPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [password, setPassword] = useState('');
  const [email, setUserName] = useState('');
  const [isLogin, setIsLogin] = useState(true);

  const handleSignIn = async (e) => {
    e.preventDefault();
    dispatch(loginUser(email, password));
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      await forgotPassword(email);
      dispatch(setNotification('Check your email for a link to reset your password', 'success'));
      setIsLogin(true);
    } catch (e) {
      console.error(e);
      dispatch(setNotification(getAuthErrorMessage(e.code)));
    }
  };

  return (
    <>
      <SEO title={'Login'} />
      <Container maxWidth={'xs'} style={{ margin: '4rem auto', width: 'max-content' }}>
        <Paper className={classes.paper} elevation={4}>
          <form
            autoComplete={'on'}
            name={isLogin ? 'Login' : 'Forgot Password'}
            onSubmit={isLogin ? handleSignIn : handleForgotPassword}
            style={{ width: 235 }}
          >
            <ResponsiveImage minHeight={20.25} images={LogoSources} />
            <Typography align={'center'} variant={'h4'} style={{ marginTop: 40 }}>
              Login
            </Typography>
            <TextField
              autoComplete={'email'}
              className={classes.field}
              id={'email'}
              label={'Email'}
              name={'email'}
              onChange={(e) => setUserName(e.target.value)}
              required
              type={'email'}
              value={email}
            />
            {isLogin && (
              <TextField
                autoComplete={'current-password'}
                className={classes.field}
                id={'password'}
                label={'Password'}
                name={'current-password'}
                onChange={(e) => setPassword(e.target.value)}
                required
                type={'password'}
                value={password}
              />
            )}

            <Button className={classes.button} color={'primary'} type={'submit'} variant={'contained'}>
              {isLogin ? 'Log In' : 'Reset Password'}
            </Button>
            <Button className={classes.forgotPasswordButton} disableRipple onClick={() => setIsLogin(!isLogin)}>
              {isLogin ? ' ' : 'Log In'}
              Forgot Password?
            </Button>
          </form>
        </Paper>
      </Container>
    </>
  );
};

export default withUnauthenticatedRoute(LoginPage);
