const INITIAL_STATE = {
  user: undefined,
  profile: undefined,
  claims: { loading: true },
  users: {
    data: [],
    loading: false,
    latest: null,
    canFetch: true,
  },
};

export const UserActionTypes = {
  LOGIN_USER: 'LOGIN_USER',
  FORGOT_PASSWORD_USER: 'FORGOT_PASSWORD_USER',
  SIGN_OUT_USER: 'SIGN_OUT_USER',

  SET_USER: 'SET_USER',
  SET_CLAIMS: 'SET_CLAIMS',

  SUBSCRIBE_USER_PROFILE: 'SUBSCRIBE_USER_PROFILE',
  SET_USER_PROFILE: 'SET_USER_PROFILE',

  FETCH_USERS: 'FETCH_USERS',
  FETCH_USERS_SUCCEEDED: 'FETCH_USERS_SUCCEEDED',

  CLEAR_STATE: 'CLEAR_STATE',
};

export const loginUser = (email, password) => ({ type: UserActionTypes.LOGIN_USER, payload: { email, password } });
export const signOutUser = () => ({ type: UserActionTypes.SIGN_OUT_USER });
export const setUser = (user) => ({ type: UserActionTypes.SET_USER, payload: { user } });
export const setClaims = (claims) => ({ type: UserActionTypes.SET_CLAIMS, payload: { claims } });
export const setUserProfile = (profile) => ({ type: UserActionTypes.SET_USER_PROFILE, payload: { profile } });
export const subscribeUserProfile = (id) => ({ type: UserActionTypes.SUBSCRIBE_USER_PROFILE, payload: { id } });
export const fetchUsers = (append, limit) => ({
  type: UserActionTypes.FETCH_USERS,
  payload: { append, limit },
});
export const fetchUsersSucceeded = (append, canFetch, latest, users) => ({
  type: UserActionTypes.FETCH_USERS_SUCCEEDED,
  payload: { append, canFetch, latest, users },
});

export const usersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.SET_USER:
      return { ...state, user: action.payload.user };

    case UserActionTypes.SET_CLAIMS:
      return { ...state, claims: action.payload.claims };

    case UserActionTypes.FETCH_USERS:
      return { ...state, users: { ...state.users, loading: false } };

    case UserActionTypes.SET_USER_PROFILE:
      return { ...state, profile: action.payload.profile };

    case UserActionTypes.FETCH_USERS_SUCCEEDED:
      const { append, canFetch, latest, users } = action.payload;
      return {
        ...state,
        users: {
          ...state.users,
          canFetch,
          data: append ? [...state.users.data, ...users] : users,
          latest,
          loading: false,
        },
      };

    case UserActionTypes.CLEAR_STATE:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export const userSelector = (state) => state.users.user;
export const userClaimsSelector = (state) => state.users.claims;

export const userProfileSelector = (state) => state.users.profile;
export const usersSelector = (state) => state.users.users;
export const usersDataSelector = (state) => state.users.users.data;
export const usersLoadingSelector = (state) => state.users.users.loading;
export const latestUsersSelector = (state) => state.users.users.latest;
