const OrganizerFields = [
  {
    id: 'companyName',
    label: 'Company Name',
    required: true,
  },
  {
    id: 'pointOfContact',
    label: 'Point of Contact',
    required: true,
  },
  {
    id: 'corporateEmail',
    label: 'Corporate Email',
    required: true,
  },
  {
    id: 'phoneNumber',
    label: 'Phone Number',
    required: true,
  },
  {
    id: 'panNumber',
    label: 'PAN Number',
    required: true,
  },
  {
    id: 'gstin',
    label: 'GSTIN',
  },
  {
    id: 'officeAddress',
    label: 'Office Address',
    fields: [
      {
        id: 'formattedAddress',
        label: 'Registered Address',
      },
      {
        id: 'city',
        label: 'City',
        required: true,
      },
      {
        id: 'state',
        label: 'State',
        required: true,
      },
      {
        id: 'country',
        label: 'Country',
        required: true,
      },
      {
        id: 'postalCode',
        label: 'Postal Code *',
      },
    ],
  },
];

export default OrganizerFields;
