import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link as MuiLink,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import Layout from 'components/navigation/Layout';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNotification } from 'redux/notifications/ducks';
import { userClaimsSelector } from 'redux/users/ducks';
import {
  addTicketVerifier,
  fetchEventBookings,
  fetchEventDetailsById,
  getTicketVerifiers,
  removeTicketVerifier,
  setEventFeature,
  setEventVerification,
} from 'services/events-service';
import { getValidationSchema } from 'utils/formik-utils';
import LoadingPage from './loading_page';
import BookingsTable from '../components/common/BookingTable';
import PageNotFound from './404';
import Routes from '../constants/routes';
import withOrganizerAuthenticatedRoute from '../components/hoc/withOrganizerAuthenticatedRoute';
import { Link } from 'react-router-dom';
import { DataGrid } from '@material-ui/data-grid';
import TicketColumns from '../constants/grids/tickets_columns';
import TicketVerifiersColumns from '../constants/grids/ticket_verifiers_columns';

const EventPage = ({
  match: {
    params: { eventId },
  },
}) => {
  const dispatch = useDispatch();

  const claims = useSelector(userClaimsSelector);

  const actionLoading = useRef(false);

  const [error, setError] = useState(false);
  const [event, setEvent] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const event = await fetchEventDetailsById(eventId);
        setEvent(event);
      } catch (e) {
        console.error(e);
        dispatch(setNotification(e.message));
        setError(true);
      } finally {
        setLoading(false);
      }
    })();
  }, [dispatch, eventId]);

  const handleAction = async (handler) => {
    if (actionLoading.current) return;

    try {
      actionLoading.current = true;
      await handler();
    } catch (e) {
      console.error(e);
      dispatch(setNotification(e.message));
    } finally {
      actionLoading.current = false;
    }
  };

  const handleFeature = async () => {
    await handleAction(async () => {
      await setEventFeature(eventId, !event.featured);
      setEvent({ ...event, featured: !event.featured });
    });
  };

  const handleVerification = async () => {
    await handleAction(async () => {
      await setEventVerification(eventId, !event.verified);
      setEvent({ ...event, verified: !event.verified });
    });
  };

  if (loading) {
    return <LoadingPage />;
  } else if (error) {
    return <PageNotFound />;
  }

  return (
    <Layout>
      <Typography variant={'h3'} paragraph>
        {event.name}
      </Typography>

      <Grid container spacing={4}>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={6} sm={4}>
            <Typography gutterBottom style={{ fontWeight: 'bold' }}>
              Company
            </Typography>
            <Typography>{event.organizer.companyName}</Typography>
          </Grid>
          {claims.admin && (
            <Grid item xs={6} sm={4}>
              <Typography gutterBottom style={{ fontWeight: 'bold' }}>
                Organizer
              </Typography>
              <Typography color={'primary'} component={Link} to={Routes.organizer(event.organizer.id)}>
                View
              </Typography>
            </Grid>
          )}
          <Grid item xs={6} sm={4}>
            {/* <Typography gutterBottom style={{ fontWeight: 'bold' }}>
              Preview
            </Typography>
            <Typography color={'primary'} component={MuiLink} href={Routes.eventPreview(event.id)} target={'_blank'}>
              Preview
            </Typography> */}
          </Grid>
          {claims.admin && (
            <>
              <Grid item xs={6} sm={4}>
                <Typography gutterBottom style={{ fontWeight: 'bold' }}>
                  Verification
                </Typography>
                <Button onClick={handleVerification} variant={'contained'} size={'small'} color={'primary'}>
                  {event.verified ? 'Un-verify' : 'Verify'}
                </Button>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography gutterBottom style={{ fontWeight: 'bold' }}>
                  Featured
                </Typography>
                <Button onClick={handleFeature} variant={'contained'} size={'small'} color={'primary'}>
                  {event.featured ? 'Un-feature' : 'Feature'}
                </Button>
              </Grid>
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <TicketVerifiersTable eventId={eventId} />
        </Grid>
        <Grid item xs={12}>
          <TicketsTable tickets={event.tickets} />
        </Grid>
        <Grid item xs={12}>
          <BookingsTable bookings={event.bookings} fetchData={() => fetchEventBookings(eventId, true)} />
        </Grid>
      </Grid>
    </Layout>
  );
};

EventPage.propTypes = { match: PropTypes.shape({ params: PropTypes.shape({ eventId: PropTypes.string }) }) };

const TicketsTable = ({ tickets }) => {
  console.log({ tickets });
  return (
    <>
      <Toolbar style={{ marginBottom: 8, padding: 0, width: '100%' }}>
        <Typography style={{ flex: '1 1 100%' }} variant={'h3'}>
          Tickets
        </Typography>
      </Toolbar>
      <DataGrid
        autoHeight
        columns={TicketColumns}
        disableColumnMenu
        disableSelectionOnClick
        hideFooterSelectedRowCount
        rows={tickets}
        pageSize={100}
        rowsPerPageOptions={[100]}
        scrollbarSize={70}
      />
    </>
  );
};

TicketsTable.propTypes = {
  tickets: PropTypes.array.isRequired,
};

const TicketVerifiersTable = ({ eventId }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [verifiers, setVerifiers] = useState([]);

  const formik = useFormik({
    initialValues: { phoneNumber: '' },
    validationSchema: getValidationSchema(['phoneNumber']),
    onSubmit: async (values) => {
      try {
        await addTicketVerifier(eventId, `+91${values.phoneNumber}`);
        await fetchTicketVerifiers();
        handleClose();
      } catch (e) {
        console.error(e.message);
        dispatch(setNotification(e.message));
      }
    },
  });

  const fetchTicketVerifiers = useCallback(async () => {
    try {
      setLoading(true);
      const { users } = await getTicketVerifiers(eventId);
      setVerifiers(users);
    } catch (e) {
      console.error(e);
      dispatch(setNotification(e.message));
    } finally {
      setLoading(false);
    }
  }, [dispatch, eventId]);

  useEffect(() => {
    (async () => {
      await fetchTicketVerifiers();
    })();
  }, [fetchTicketVerifiers]);

  const removeTicketVerifiers = async (userIds) => {
    try {
      setSelected([]);
      await Promise.all(userIds.map((userId) => removeTicketVerifier(eventId, userId)));
    } catch (e) {
      console.error(e);
      dispatch(setNotification(e.message));
    } finally {
      await fetchTicketVerifiers();
    }
  };

  const handleClose = () => setOpen(false);

  return (
    <>
      <Toolbar style={{ marginBottom: 8, padding: 0, width: '100%' }}>
        <Typography style={{ flex: '1 1 100%' }} variant={'h3'}>
          Ticket Verifiers
        </Typography>
        <Tooltip title={'Add'}>
          <span>
            <IconButton aria-label={'add'} disabled={Boolean(selected.length)} onClick={() => setOpen(true)}>
              <Add />
            </IconButton>
          </span>
        </Tooltip>

        <Tooltip title={'Delete'}>
          <span>
            <IconButton
              aria-label={'delete'}
              disabled={selected.length !== 1}
              onClick={() => removeTicketVerifiers(selected)}
            >
              <Delete />
            </IconButton>
          </span>
        </Tooltip>
      </Toolbar>
      <DataGrid
        autoHeight
        columns={TicketVerifiersColumns}
        checkboxSelection
        disableColumnMenu
        disableSelectionOnClick
        hideFooterSelectedRowCount
        loading={loading}
        onSelectionModelChange={(selected) => setSelected(selected)}
        pageSize={25}
        rows={verifiers}
        rowsPerPageOptions={[25]}
        scrollbarSize={70}
        selectionModel={selected}
      />
      <Dialog maxWidth={'xs'} open={open} onClose={handleClose} scroll={'body'}>
        <DialogTitle align={'center'}>Add Ticket Verifier</DialogTitle>
        <DialogContent dividers>
          <form name={'Add Verifier'} onSubmit={formik.handleSubmit}>
            <Grid alignItems={'center'} container justifyContent={'center'} spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name={'phoneNumber'}
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  label={'Phone Number'}
                  error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                  helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                  variant={'outlined'}
                  color={'primary'}
                />
              </Grid>
              <Grid item xs={12}>
                <Button fullWidth type={'submit'} style={{ marginTop: 8 }} variant={'contained'} color={'primary'}>
                  Add
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

TicketVerifiersTable.propTypes = {
  eventId: PropTypes.string.isRequired,
};

export default withOrganizerAuthenticatedRoute(EventPage);
