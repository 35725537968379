import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const siteMetadata = {
  title: 'Ticketing Console',
  description: 'Seamless Ticketing Console for Business Management',
  author: '@ticketing',
  siteUrl:
    process.env.REACT_APP_BUILD_TYPE === 'ADMIN'
      ? 'https://admin.theticketing.in'
      : 'https://organizer.theticketing.in',
};

const SEO = ({ description, lang, meta, title }) => {
  const metaDescription = description || siteMetadata.description;

  // 155 Character description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          prefix: 'og: http://ogp.me/ns#',
          property: `og:title`,
          content: title,
        },
        {
          prefix: 'og: http://ogp.me/ns#',
          property: `og:description`,
          content: metaDescription,
        },
        {
          prefix: 'og: http://ogp.me/ns#',
          property: `og:type`,
          content: `website`,
        },
        {
          prefix: 'og: http://ogp.me/ns#',
          property: `og:url`,
          content: siteMetadata.siteUrl + window.location.pathname,
        },
        {
          prefix: 'og: http://ogp.me/ns#',
          property: `og:image`,
          content: siteMetadata.siteUrl + '/logo512.png',
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: `@_prashant_a_`,
        },
        {
          name: `twitter:url`,
          content: siteMetadata.siteUrl + window.location.pathname,
        },
        {
          name: `twitter:site`,
          content: siteMetadata.siteUrl,
        },
        {
          prefix: 'og: http://ogp.me/ns#',
          name: `twitter:image`,
          content: siteMetadata.siteUrl + '/logo512.png',
        },
      ].concat(meta)}
    />
  );
};

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
