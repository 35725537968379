import firebase from 'firebase/app';
import UsersPage from 'pages/users_table_page';
import UserPage from 'pages/user_page';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Notification from './components/common/Notification';
import ScrollToTop from './components/navigation/ScrollToTop';
import Routes from './constants/routes';
import PageNotFound from './pages/404';
import EventFormPage from './pages/event_form_page';
import EventPage from './pages/event_page';
import LoginPage from './pages/login_page';
import OrganizersPage from './pages/organizers_table_page';
import OrganizerPage from './pages/organizer_page';
import PastEventsPage from './pages/past_events_table_page';
import ProfilePage from './pages/profile_page';
import UpcomingEventsPage from './pages/upcoming_events_table_page';
import { setClaims, setUser, setUserProfile } from './redux/users/ducks';

function App() {
  const authUnsubscribe = useRef(() => {});
  const authInitialized = useRef(false);
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.setItem('getSelectedItem', 'Upcoming Events');
    authUnsubscribe.current = firebase.auth().onAuthStateChanged((user) => {
      authInitialized.current = true;
      dispatch(setUser(user));
      // cloudFunction('organizers-promoteToOrganiser')({ id: 'rVpsgtExeGQ4ldSy05moytBw8wE3' }).then((res) =>
      //   console.log(res.data)
      // );
      // cloudFunction('organizers-makeAdmin')().then((res) => console.log(res.data));
      if (user !== null) {
        user.getIdTokenResult().then((value) => dispatch(setClaims(value.claims)));
      } else {
        dispatch(setClaims({ admin: false, loading: false }));
        dispatch(setUserProfile(null));
      }
    });
    return authUnsubscribe.current;
  }, [dispatch]);

  return (
    <Router>
      <ScrollToTop />
      <Notification />
      <Switch>
        <Route exact path={Routes.LANDING} component={LoginPage} />

        <Route exact path={Routes.PAST_EVENTS} component={PastEventsPage} />
        <Route exact path={Routes.UPCOMING_EVENTS} component={UpcomingEventsPage} />
        <Route exact path={Routes.event(':eventId')} component={EventPage} />
        <Route exact path={Routes.eventForm(':eventId')} component={EventFormPage} />
        <Route exact path={Routes.PROFILE} component={ProfilePage} />

        <Route exact path={Routes.ORGANIZERS} component={OrganizersPage} />
        <Route exact path={Routes.organizer(':id')} component={OrganizerPage} />
        <Route exact path={Routes.USERS} component={UsersPage} />
        <Route exact path={Routes.user(':id')} component={UserPage} />

        <Route component={PageNotFound} />
      </Switch>
    </Router>
  );
}

export default App;
