import { eventChannel } from '@redux-saga/core';
import { call, put, select, take, takeLatest } from '@redux-saga/core/effects';
import {
  fetchPastEvents,
  fetchPastEventsByOrganizer,
  getUpcomingEventsListener,
  getUpcomingOrganizerEventsListener,
} from '../../services/events-service';
import { setNotification } from '../notifications/ducks';
import {
  EventActionTypes,
  latestPastEventsSelector,
  setUpcomingEvents,
  setUpcomingEventsInitStatus,
  upcomingEventsInitSelector,
} from './ducks';
import { userClaimsSelector } from '../users/ducks';

export function* eventsSaga() {
  yield takeLatest(EventActionTypes.FETCH_PAST_EVENTS, fetchPastEventsSaga);
  yield takeLatest(EventActionTypes.SUBSCRIBE_UPCOMING_EVENTS, listenUpcomingEventsSaga);
}

function* fetchPastEventsSaga(action) {
  const { append, limit } = action.payload;

  const claims = yield select(userClaimsSelector);
  let latest = append ? yield select(latestPastEventsSelector) : undefined;
  let events = [];

  try {
    const snap = yield call(
      process.env.REACT_APP_BUILD_TYPE === 'ADMIN' ? fetchPastEvents : fetchPastEventsByOrganizer,
      latest,
      limit,
      claims.organizer
    );

    if (snap.size) {
      events = snap.docs.map((doc) => doc.data());
      latest = snap.docs.slice(-1).pop();
    }
    yield put({
      type: EventActionTypes.FETCH_PAST_EVENTS_SUCCEEDED,
      payload: { append, canFetch: snap.size === limit, latest, events },
    });
  } catch (e) {
    console.error(e);
    yield put(setNotification(e.message));
  }
}

const fetchUpcomingEventsListener = (organizerId) =>
  eventChannel((emit) =>
    process.env.REACT_APP_BUILD_TYPE === 'ADMIN'
      ? getUpcomingEventsListener(emit)
      : getUpcomingOrganizerEventsListener(emit, organizerId)
  );

function* listenUpcomingEventsSaga(action) {
  const init = yield select(upcomingEventsInitSelector);
  if (!init) return;
  yield put(setUpcomingEventsInitStatus(false));

  try {
    const listener = fetchUpcomingEventsListener(action.payload.organizerId);
    while (true) {
      const events = yield take(listener);
      yield put(setUpcomingEvents(events));
    }
  } catch (e) {
    console.error(e.message);
    yield put(setUpcomingEventsInitStatus(true));
    yield put(setNotification(e.message, 'error'));
  }
}
