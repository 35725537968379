const INITIAL_STATE = {
  past: {
    data: [],
    loading: false,
    latest: null,
    canFetch: true,
  },
  upcoming: {
    data: [],
    loading: true,
    init: true,
  },
};

export const EventActionTypes = {
  FETCH_PAST_EVENTS: 'FETCH_PAST_EVENTS',
  FETCH_PAST_EVENTS_SUCCEEDED: 'FETCH_PAST_EVENTS_SUCCEEDED',

  SUBSCRIBE_UPCOMING_EVENTS: 'LISTEN_UPCOMING_EVENTS',
  SET_UPCOMING_EVENTS: 'SET_UPCOMING_EVENTS',
  SET_UPCOMING_EVENTS_INIT_STATUS: 'SET_UPCOMING_EVENTS_INIT_STATUS',

  ADD_EVENT: 'ADD_EVENT',
  UPDATE_EVENT: 'UPDATE_EVENT',
  DISABLE_EVENTS: 'DISABLE_EVENTS',

  CLEAR_STATE: 'CLEAR_STATE',
};

export const fetchPastEvents = (append, limit) => ({
  type: EventActionTypes.FETCH_PAST_EVENTS,
  payload: { append, limit },
});

export const subscribeUpcomingEvents = (organizerId) => ({
  type: EventActionTypes.SUBSCRIBE_UPCOMING_EVENTS,
  payload: { organizerId },
});
export const setUpcomingEvents = (events) => ({ type: EventActionTypes.SET_UPCOMING_EVENTS, payload: { events } });
export const setUpcomingEventsInitStatus = (status) => ({
  type: EventActionTypes.SET_UPCOMING_EVENTS_INIT_STATUS,
  payload: { status },
});

export const eventsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EventActionTypes.SET_UPCOMING_EVENTS:
      return { ...state, upcoming: { data: action.payload.events, loading: false } };

    case EventActionTypes.SET_UPCOMING_EVENTS_INIT_STATUS:
      return { ...state, upcoming: { ...state.upcoming, init: action.payload.status } };

    case EventActionTypes.FETCH_PAST_EVENTS:
      return { ...state, past: { ...state.past, loading: true } };

    case EventActionTypes.FETCH_PAST_EVENTS_SUCCEEDED:
      const { append, canFetch, latest, events } = action.payload;
      return {
        ...state,
        past: {
          ...state.past,
          canFetch,
          data: append ? [...state.past.data, ...events] : events,
          latest,
          loading: false,
        },
      };

    case EventActionTypes.CLEAR_STATE:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export const pastEventsSelector = (state) => state.events.past;
export const latestPastEventsSelector = (state) => state.events.past.latest;

export const upcomingEventByIdSelector = (state, id) =>
  id ? state.events.upcoming.data.find((event) => event.id === id) : null;
export const upcomingEventsSelector = (state) => state.events.upcoming;
export const upcomingEventsInitSelector = (state) => state.events.upcoming.init;
