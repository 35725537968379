export const getFetchResponse = async (res) => {
  let payload;
  try {
    payload = await res.json();
  } catch (e) {
    payload = { message: 'An unknown error has occurred.' };
  }
  if (res.ok) {
    return payload;
  }
  const message = payload?.message instanceof Array ? payload.message.join(', ') : payload?.message?.toString();
  throw new Error(`${payload?.error ? `${payload.error}: ` : ''}${message}`);
};
