import firebase from 'firebase/app';
import { identity, pickBy } from 'lodash-es';
import shortUUID from 'short-uuid';
import { formatDateTime } from '../utils/date-time-utils';
import { formatBookingsForExport } from '../utils/export-utils';
import { getFetchResponse } from '../utils/fetch-utils';
import { urlify } from '../utils/string-utils';

const apiUrl = `${process.env.REACT_APP_BACKEND_BASE_URL}/events`;

const uploadImage = async (file, pathPrefix) => {
  if (!file) {
    return;
  }
  const extension = file.name.split('.').pop() || 'png';
  const fileRef = firebase.storage().ref(`${pathPrefix}.${extension}`);
  await fileRef.put(file).then((snap) => snap.ref);
};

// export const putEvent = async (
//   event,
//   { artistImage, imagesArray, squareImage, tallImage },
//   existingImagesLength,
//   manualAddress
// ) => {
//   const db = firebase.firestore();
//   const { FieldValue } = firebase.firestore;
//   let ref;

//   const isCreate = !Boolean(event.id);
//   if (isCreate) {
//     ref = db.collection('events').doc();
//     event.id = ref.id;
//   } else {
//     ref = db.collection('events').doc(event.id);
//   }

//   const ticketTimes = event.tickets.map((ticket) => ticket.datetime.getTime());
//   event.endDate = new Date(Math.max(...ticketTimes));
//   event.startDate = new Date(Math.min(...ticketTimes));
//   event.updatedAt = FieldValue.serverTimestamp();
//   event.url = urlify(event.name);

//   if (isCreate) {
//     event.manualAddress = manualAddress ? manualAddress : null;
//     event.createdAt = FieldValue.serverTimestamp();
//     event.featured = false;
//     event.soldOut = false;
//     event.verified = false;
//   }

//   console.log('Event data prepared:', event);

//   event.tickets.forEach((ticket) => {
//     ticket.datetime = new Date(ticket.datetime.setSeconds(0, 0));
//     ticket.price = parseInt(ticket.price);
//     ticket.totalTickets = parseInt(ticket.totalTickets);
//     if (!ticket.id) {
//       ticket.id = shortUUID.generate();
//       ticket.soldTickets = 0;
//     }
//   });
//   event.minPrice = Math.min(...event.tickets.map((ticket) => ticket.price));

//   if (isCreate) {
//     await ref.set(event);
//   } else {
//     console.log('Starting putEvent transaction');
//     await db.runTransaction(async (tx) => {
//       console.log('Inside transaction');
//       const dbEvent = await tx.get(ref).then((doc) => doc.data());
//       dbEvent.tickets.forEach((dbTicket) => {
//         const ticket = event.tickets.find(({ id }) => id === dbTicket.id);
//         if (ticket) {
//           ticket.soldTickets = dbTicket.soldTickets;
//         }
//       });
//       const updateJson = { ...event };
//       for (let i = imagesArray.length; i < existingImagesLength; i++) {
//         updateJson[`images.${i}`] = FieldValue.delete();
//       }
//       if (manualAddress) {
//         updateJson.manualAddress = manualAddress; // Update manualAddress only if a value is provided

//       }
//       await tx.update(ref, updateJson);
//     });
//   }

//   await Promise.all([
//     uploadImage(artistImage, `events/${event.id}/artist`),
//     uploadImage(squareImage, `events/${event.id}/square`),
//     uploadImage(tallImage, `events/${event.id}/featured`),
//     ...imagesArray.map((image, i) => uploadImage(image.file, `events/${event.id}/images/${i}`)),
//   ]);
// };

export const putEvent = async (
  event,
  { artistImage, imagesArray, squareImage, tallImage },
  existingImagesLength,
  manualAddress
) => {
  const db = firebase.firestore();
  const { FieldValue } = firebase.firestore;
  let ref;

  const isCreate = !Boolean(event.id);
  if (isCreate) {
    ref = db.collection('events').doc();
    event.id = ref.id;
  } else {
    ref = db.collection('events').doc(event.id);
  }

  const ticketTimes = event.tickets.map((ticket) => ticket.datetime.getTime());
  event.endDate = new Date(Math.max(...ticketTimes));
  event.startDate = new Date(Math.min(...ticketTimes));
  event.updatedAt = FieldValue.serverTimestamp();
  event.url = urlify(event.name);

  if (isCreate) {
    event.manualAddress = manualAddress ? manualAddress : null;
    event.createdAt = FieldValue.serverTimestamp();
    event.featured = false;
    event.soldOut = false;
    event.verified = false;
  }

  event.tickets.forEach((ticket) => {
    ticket.datetime = new Date(ticket.datetime.setSeconds(0, 0));
    ticket.price = parseInt(ticket.price);
    ticket.totalTickets = parseInt(ticket.totalTickets);
    if (!ticket.id) {
      ticket.id = shortUUID.generate();
      ticket.soldTickets = 0;
    }
  });
  event.minPrice = Math.min(...event.tickets.map((ticket) => ticket.price));

  if (isCreate) {
    await ref.set(event);
  } else {
    await db.runTransaction(async (tx) => {
      const dbEvent = await tx.get(ref).then((doc) => doc.data());
      dbEvent.tickets.forEach((dbTicket) => {
        const ticket = event.tickets.find(({ id }) => id === dbTicket.id);
        if (ticket) {
          ticket.soldTickets = dbTicket.soldTickets !== undefined ? dbTicket.soldTickets : 0;
        }
      });
      const updateJson = { ...event };
      for (let i = imagesArray.length; i < existingImagesLength; i++) {
        updateJson[`images.${i}`] = FieldValue.delete();
      }
      if (manualAddress) {
        updateJson.manualAddress = manualAddress;
      }

      await tx.update(ref, updateJson);
    });
  }

  await Promise.all([
    uploadImage(artistImage, `events/${event.id}/artist`),
    uploadImage(squareImage, `events/${event.id}/square`),
    uploadImage(tallImage, `events/${event.id}/featured`),
    ...imagesArray.map((image, i) => uploadImage(image.file, `events/${event.id}/images/${i}`)),
  ]);
};

export const getUpcomingEventsListener = (emit) =>
  firebase
    .firestore()
    .collection('events')
    .where('endDate', '>', new Date())
    .orderBy('endDate', 'asc')
    .onSnapshot((snap) => {
      emit(snap.docs.map((doc) => doc.data()));
    });

export const getUpcomingOrganizerEventsListener = (emit, organizerId) =>
  firebase
    .firestore()
    .collection('events')
    .where('organizer.id', '==', organizerId)
    .where('endDate', '>', new Date())
    .orderBy('endDate', 'asc')
    .onSnapshot((snap) => {
      emit(snap.docs.map((doc) => doc.data()));
    });

export const fetchPastEvents = async (latest, limit) => {
  let query = firebase.firestore().collection('events').where('endDate', '<=', new Date()).orderBy('endDate', 'desc');
  if (latest) query = query.startAfter(latest);
  return query.get();
};

export const fetchPastEventsByOrganizer = async (latest, limit, organizerId) => {
  let query = firebase
    .firestore()
    .collection('events')
    .where('organizer.id', '==', organizerId)
    .where('endDate', '<=', new Date())
    .orderBy('endDate', 'desc');

  if (latest) query = query.startAfter(latest);

  return query.limit(limit).get();
};

export const formatEventsForExport = (data) => {
  data.categories = data.categories.join(', ');
  data.languages = data.languages.join(', ');
  data.createdAt = formatDateTime(data.createdAt);
  data.updatedAt = formatDateTime(data.updatedAt);
  data.startDate = formatDateTime(data.startDate);
  data.endDate = formatDateTime(data.endDate);

  delete data.featuredImage;
  delete data.images;
  delete data.squareImage;
  delete data.tickets;

  data.artistName = data.artist.name;
  data.artistInfo = data.artist.about;
  data.country = data.location.country;
  data.state = data.location.state;
  data.city = data.location.city;
  data.postalCode = data.location.postalCode;
  data.address = data.location.formattedAddress;
  data.organizerId = data.organizer.id;
  data.companyName = data.organizer.companyName;
  data.companyPan = data.organizer.panNumber;
  delete data.artist;
  delete data.location;
  delete data.organizer;

  return data;
};

export const fetchAllPastEvents = async () =>
  firebase
    .firestore()
    .collection('events')
    .where('endDate', '<=', new Date())
    .orderBy('endDate', 'desc')
    .get()
    .then((snap) => snap.docs.map((doc) => formatEventsForExport(doc.data())));

export const fetchAllPastEventsByOrganizer = async (organizerId) =>
  !organizerId
    ? []
    : firebase
        .firestore()
        .collection('events')
        .where('organizer.id', '==', organizerId)
        .where('endDate', '<=', new Date())
        .orderBy('endDate', 'desc')
        .get()
        .then((snap) => snap.docs.map((doc) => formatEventsForExport(doc.data())));

export const fetchAllUpcomingEvents = async () =>
  firebase
    .firestore()
    .collection('events')
    .where('endDate', '>', new Date())
    .orderBy('endDate', 'asc')
    .get()
    .then((snap) => snap.docs.map((doc) => formatEventsForExport(doc.data())));

export const fetchAllUpcomingEventsByOrganizer = async (organizerId) =>
  firebase
    .firestore()
    .collection('events')
    .where('organizer.id', '==', organizerId)
    .where('endDate', '>', new Date())
    .orderBy('endDate', 'asc')
    .get()
    .then((snap) => snap.docs.map((doc) => formatEventsForExport(doc.data())));

export const fetchEventDetailsById = async (eventId) => {
  const [event, bookings] = await Promise.all([
    firebase
      .firestore()
      .collection('events')
      .doc(eventId)
      .get()
      .then((doc) => doc.data()),
    fetchEventBookings(eventId),
  ]);

  event.bookings = bookings;
  console.log(bookings);
  return event;
};

export const fetchEventBookings = async (eventId, format = false) =>
  firebase
    .firestore()
    .collection('bookings')
    .where('event.id', '==', eventId)
    .where('paymentStatus', '==', 'PAYMENT_COMPLETED')
    .orderBy('createdAt', 'desc')
    .get()
    .then((snap) => {
      const bookings = snap.docs.map((doc) => doc.data());
      if (format) {
        return formatBookingsForExport(bookings);
      }
      console.log(bookings);
      return bookings;
    });

export const setEventFeature = async (eventId, featuredStatus) =>
  firebase.firestore().collection('events').doc(eventId).update({ featured: featuredStatus });

export const setEventVerification = async (eventId, verificationStatus) =>
  firebase.firestore().collection('events').doc(eventId).update({ verified: verificationStatus });

export const getTicketVerifiers = async (eventId) => {
  const token = await firebase.auth().currentUser.getIdToken();
  const headers = pickBy({ 'authorization': token, 'content-type': 'application/json' }, identity);
  return fetch(`${apiUrl}/${eventId}/verifiers`, { headers }).then(getFetchResponse);
};

export const addTicketVerifier = async (eventId, phoneNumber) => {
  const token = await firebase.auth().currentUser.getIdToken();
  const headers = pickBy({ 'authorization': token, 'content-type': 'application/json' }, identity);
  return fetch(`${apiUrl}/${eventId}/verifiers`, {
    method: 'POST',
    body: JSON.stringify({ phoneNumber }),
    headers,
  }).then(getFetchResponse);
};
export const removeTicketVerifier = async (eventId, userId) => {
  const token = await firebase.auth().currentUser.getIdToken();
  const headers = pickBy({ authorization: token }, identity);
  return fetch(`${apiUrl}/${eventId}/verifiers/${userId}`, {
    method: 'DELETE',
    headers,
  }).then(getFetchResponse);
};
