import firebase from 'firebase/app';
import { formatDateTime } from '../utils/date-time-utils';
import { formatEventsForExport } from './events-service';
import { identity, pickBy } from 'lodash-es';
import { getFetchResponse } from '../utils/fetch-utils';

const apiUrl = `${process.env.REACT_APP_BACKEND_BASE_URL}/organizers`;

export const updateOrganizer = async (id, data) =>
  firebase
    .firestore()
    .collection('organizers')
    .doc(id)
    .update({ ...data, updatedAt: firebase.firestore.FieldValue.serverTimestamp() });

export const fetchOrganizers = async (latest, limit) => {
  let query = firebase.firestore().collection('organizers');

  if (latest) query = query.startAfter(latest);

  return query.limit(limit).get();
};

export const fetchAllOrganizers = async () =>
  firebase
    .firestore()
    .collection('organizers')
    .get()
    .then((snap) =>
      snap.docs.map((doc) => {
        const data = doc.data();
        data.postalCode = data.officeAddress.postalCode;
        data.country = data.officeAddress.country;
        data.state = data.officeAddress.state;
        data.city = data.officeAddress.city;
        data.officeAddress = data.officeAddress.formattedAddress;
        data.owners = data.owners.join(', ');
        data.createdAt = formatDateTime(data.createdAt);
        data.updatedAt = formatDateTime(data.updatedAt);
        return data;
      })
    );

export const promoteUserToOrganiser = async (uid) => {
  const token = await firebase.auth().currentUser.getIdToken();
  const headers = pickBy({ authorization: token }, identity);
  await fetch(`${apiUrl}/${uid}`, { method: 'POST', headers }).then(getFetchResponse);
};

export const fetchOrganizerById = async (id) => {
  const db = firebase.firestore();

  const [organizer, events] = await Promise.all([
    db
      .collection('organizers')
      .doc(id)
      .get()
      .then((doc) => doc.data()),
    fetchOrganizerEvents(id),
  ]);
  if (!organizer) throw new Error('Organizer not found');

  return { ...organizer, events };
};

export const fetchOrganizerEvents = (id, format = false) =>
  firebase
    .firestore()
    .collection('events')
    .where('organizer.id', '==', id)
    .orderBy('endDate', 'desc')
    .get()
    .then((snap) => snap.docs.map((doc) => (format ? formatEventsForExport(doc.data()) : doc.data())));

export const disableOrDeleteOrganizer = async (organizerId) => {
  const token = await firebase.auth().currentUser.getIdToken();
  const headers = pickBy({ authorization: token }, identity);
  await fetch(`${apiUrl}/${organizerId}`, { method: 'DELETE', headers }).then(getFetchResponse);
};

// export const disableOrDeleteUpcomingEvents = async (upcomingEventId) => {
//   const token = await firebase.auth().currentUser.getIdToken();
//   const headers = pickBy({ authorization: token }, identity);
//   await fetch(`${apiUrl2}/${eventsId}`, { method: 'DELETE', headers }).then(getFetchResponse);
// };

export const enableOrganizer = async (organizerId) => {
  const token = await firebase.auth().currentUser.getIdToken();
  const headers = pickBy({ authorization: token }, identity);
  await fetch(`${apiUrl}/${organizerId}/enable`, { method: 'PATCH', headers }).then(getFetchResponse);
};
